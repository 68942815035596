import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { GetActiveContractsResponseDto } from '../../core/api/dtos';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { ContractTerminationStore } from '../contract-termination-store';
import { BottomPaddedGridItemM, BottomPaddedGridItemS } from '../styles';
import { TerminationSuccessPageContainer } from '../termination-success/termination-success-page-container';
import { ContractTerminationForm } from './contract-termination-form';

export interface ContractViewProps {
    contract: GetActiveContractsResponseDto;
}

export const ContractView = observer((props: ContractViewProps) => {
    const { translate } = injectTSDI(I18n);
    const { contractToTerminateId, getStudio } = injectTSDI(
        ContractTerminationStore
    );
    const studioPublicDto = getStudio(props.contract.studioId)?.studioPublicDto;

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container>
                    <Grid item xs={5}>
                        <Typography>
                            {translate('public.content.common.studio')}
                        </Typography>
                    </Grid>
                    <BottomPaddedGridItemS container item xs={7}>
                        <Grid item xs={12}>
                            <Typography>{studioPublicDto?.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                {`${studioPublicDto?.street} ${studioPublicDto?.houseNumber}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                {`${studioPublicDto?.zip} ${studioPublicDto?.city}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                {studioPublicDto?.telephone}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{studioPublicDto?.email}</Typography>
                        </Grid>
                    </BottomPaddedGridItemS>
                    <BottomPaddedGridItemM item xs={5}>
                        <Typography>
                            {translate('public.content.common.contract')}
                        </Typography>
                    </BottomPaddedGridItemM>
                    <BottomPaddedGridItemM item xs={7}>
                        <Typography data-role="contract-name">
                            {props.contract.contractName}
                        </Typography>
                    </BottomPaddedGridItemM>
                    <BottomPaddedGridItemS item xs={12}>
                        {props.contract.cancelled ? (
                            <Typography>
                                {translate(
                                    'public.content.terminate.contract.already.terminated'
                                )}
                            </Typography>
                        ) : (
                            <ContractTerminationForm
                                contract={props.contract}
                            />
                        )}
                    </BottomPaddedGridItemS>
                    {props.contract.contractId === contractToTerminateId && (
                        <Grid item xs={12}>
                            <TerminationSuccessPageContainer />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
});
