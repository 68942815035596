import 'mobx-wrap-request';
import 'typeface-roboto';
import '../core/polyfills';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { external, inject } from 'tsdi';

import { AdditionalAgreement } from '../additional-agreement';
import { CommunicationMatrix } from '../communication-matrix';
import { Footer } from '../components/footer';
import { LoadingPage } from '../components/loading-page';
import { ContractTermination } from '../contract-termination';
import { isDev } from '../core/common/environments';
import { getEnvironment } from '../core/endpoint';
import { I18n } from '../core/i18n';
import { links, Router } from '../core/router';
import { UrlStore } from '../core/url-store';
import { EmailVerification } from '../email-verification';
import { Message } from '../message';
import { PromotionTest } from '../promotion-test';
import { ThemeStore } from './theme-store';
import { StudioStore } from '../core/studio-store';

const GlobalStyle = createGlobalStyle`
    html, body, #main {
        width: 100%;
        height: 100%;
    }
`;

const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ContentWrapper = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;
`;

@observer
@external
export class App extends React.Component {
    @inject
    private router!: Router;
    @inject
    private themeStore!: ThemeStore;
    @inject
    private i18n!: I18n;
    @inject
    private urlStore!: UrlStore;
    @inject
    private studioStore!: StudioStore;

    public render(): JSX.Element {
        return (
            <MuiThemeProvider theme={this.themeStore.theme}>
                <CssBaseline />
                {this.renderApp()}
                <Message />
                <GlobalStyle />
            </MuiThemeProvider>
        );
    }

    private renderApp(): JSX.Element {
        const { route } = this.router;
        const isShowFooter = route !== links.contractTermination();
        const studioName = this.studioStore.studioInfo.result?.studioName;

        return (
            <AppWrapper>
                <ContentWrapper data-role="public-content">
                    {this.renderRoute()}
                    {isShowFooter &&
                        <Footer
                            renderLogo={!studioName}
                            studioName={studioName}
                            legalLinks={this.studioStore.legalLinks.result}
                        />
                    }

                </ContentWrapper>
            </AppWrapper>
        );
    }

    private renderRoute(): JSX.Element {
        const { route } = this.router;
        const { translate } = this.i18n;
        const { studio, email, tenant } = this.urlStore;

        switch (route) {
            case links.emailVerification():
                return (
                    <EmailVerification
                        verified={true}
                        studioName={studio}
                        email={email}
                    />
                );
            case links.emailRejection():
                return (
                    <EmailVerification
                        verified={false}
                        studioName={studio}
                        email={email}
                    />
                );
            case links.communicationSettings():
                return (
                    <CommunicationMatrix
                        heading={translate(
                            'public.content.communication.header'
                        )}
                    />
                );
            case links.additionalAgreement():
                return <AdditionalAgreement />;
            case links.contractTermination():
                return <ContractTermination tenant={tenant} />;
            case links.bookingConfirmation():
                const ComponentConfirmation = React.lazy(() =>
                    import('../booking-confirmation')
                );

                return (
                    <Suspense>
                        <ComponentConfirmation />
                    </Suspense>
                );
            case links.bookingCancelation():
                const ComponentCancelation = React.lazy(() =>
                    import('../booking-cancelation')
                );

                return (
                    <Suspense>
                        <ComponentCancelation />
                    </Suspense>
                );
            case links.promotionTest():
                return isDev(getEnvironment()) ? (
                    <PromotionTest />
                ) : (
                    <LoadingPage />
                );
            default:
                return <LoadingPage />;
        }
    }
}

function Suspense(props: { children: React.ReactChild }) {
    return (
        <React.Suspense fallback={<LoadingPage />}>
            {props.children}
        </React.Suspense>
    );
}
