import { Environment, isValidEnvironment } from './common/environments';
import { getUrlParams } from './common/utils';
import { Tenant } from './tenants';

export interface Host {
    tenant: Tenant;
    environment: Environment;
}

function locationToHost(): Host {
    const urlEnv = window.location.href.split('.')[1] as Environment;
    const tenant = getUrlParams().get('tenant');

    const env = isValidEnvironment(urlEnv) ? urlEnv : Environment.PRODUCTION;

    return {
        tenant: tenant as Tenant,
        environment: env
    };
}

function hostToEndpoint(host: Host): string {
    const port = host.environment === 'local' ? ':9443' : '';
    return host.environment === Environment.PRODUCTION
        ? `//${host.tenant}.api.magicline.com`
        : `//${host.tenant}.api.${host.environment}.magicline.com${port}`;
}

/*
 * exports
 */

const host = locationToHost();
const endPoint = hostToEndpoint(host);

export function getTenant(): Tenant {
    return host.tenant;
}

export function getEnvironment(): Environment {
    return host.environment;
}

export function getEndpoint(): string {
    return endPoint;
}
