import { MenuItem, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { CancelContractManualRequestDto } from '../../core/api/dtos';
import { WrappedField } from '../../core/common/forms';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';

interface CancellationDateTypeSelectProps {
    cancellationDateTypeField: WrappedField<CancelContractManualRequestDto['cancellationDateType']>;
    cancellationTypeField: WrappedField<CancelContractManualRequestDto['cancellationType']>;
}

export const CancellationDateTypeSelect = observer((props: CancellationDateTypeSelectProps) => {
    const { translate } = injectTSDI(I18n);
    const { cancellationDateTypeField, cancellationTypeField } = props;

    return (
        <TextField
            fullWidth
            select
            required
            variant="outlined"
            label={translate(
                'public.content.terminate.contract.by'
            )}
            value={cancellationDateTypeField.value}
            onChange={event =>
                cancellationDateTypeField.reset(
                    event.target
                        .value as CancelContractManualRequestDto['cancellationDateType']
                )
            }
            error={Boolean(cancellationDateTypeField.error)}
            helperText={translate(
                cancellationDateTypeField.error
            )}
            disabled={
                cancellationTypeField.value ===
                'EXTRAORDINARY_CANCELLATION'
            }
        >
            <MenuItem
                key="NEXT_POSSIBLE_CANCELLATION_DATE"
                value="NEXT_POSSIBLE_CANCELLATION_DATE"
                data-role="contract-termination-date-next-possible"
            >
                {translate(
                    'public.content.terminate.contract.next.possible.cancellation.date'
                )}
            </MenuItem>
            <MenuItem
                key="ABSOLUTE_CANCELLATION_DATE"
                value="ABSOLUTE_CANCELLATION_DATE"
                data-role="contract-termination-date-absolute"
            >
                {translate(
                    'public.content.terminate.contract.absolute.cancellation.date'
                )}
            </MenuItem>
        </TextField>
    );
});
