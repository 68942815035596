import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { external, inject } from 'tsdi';

import { Footer } from '../components/footer';
import { CentralLegalLinkDto } from '../core/api/dtos';
import { injectTSDI } from '../core/common/tsdi';
import { I18n } from '../core/i18n';
import { ContractTerminationPage } from './contract-termination-page';
import { ContractTerminationStore } from './contract-termination-store';
import { BottomPaddedGridItemM, BottomPaddedGridItemS } from './styles';
import { ManualTerminationSuccessPageContainer } from './termination-success/manual-termination-succes-page-container';
import { TerminationSuccessPageContainer } from './termination-success/termination-success-page-container';
import { StudioStore } from '../core/studio-store';

const CenterGrid = styled(Grid)`
    text-align: justify;
    padding: 0 10px 0 10px;
    margin-top: 50px;
`;

const StyledBottomPaddedGridItemM = styled(BottomPaddedGridItemM)`
    width: inherit;
`;

export interface ContractTerminationProps {
    tenant?: string | null;
}

@observer
@external
export class ContractTermination extends React.Component<
    ContractTerminationProps
> {
    @inject
    private i18n!: I18n;

    @inject
    private studioStore!: StudioStore;

    public render(): JSX.Element {
        const {
            cancelContractRequest,
            cancelContractManualRequest,
            cancelContractManualForm,
            getStudio
        } = injectTSDI(ContractTerminationStore);

        const {
            legalLinks
        } = this.studioStore;

        const defaultValue: CentralLegalLinkDto[] = [
            {
                centralLegalLinkDocumentType: 'IMPRINT',
                legalLinkType: 'EXTERNAL_URL',
                content: '.'
            },
            {
                centralLegalLinkDocumentType: 'PRIVACY',
                content: '.',
                legalLinkType: 'EXTERNAL_URL'
            }
        ];

        const selectedStudioName = getStudio(
            cancelContractManualForm.wrappedField.studioId.value
        )?.studioPublicDto?.name;

        const contractTerminationLegalLinks =
            legalLinks.fetched ? legalLinks.result : defaultValue;

        return (
            <>
                <CenterGrid
                    container
                    direction="column"
                    alignContent="space-around"
                >
                    <StyledBottomPaddedGridItemM
                        item
                        xs={12}
                        sm={10}
                        md={8}
                        lg={7}
                        xl={5}
                    >
                        <Typography variant="h4">
                            {this.i18n.translate(
                                'public.content.terminate.contract'
                            )}
                        </Typography>
                    </StyledBottomPaddedGridItemM>
                    <BottomPaddedGridItemS
                        item
                        xs={12}
                        sm={10}
                        md={8}
                        lg={7}
                        xl={5}
                    >
                        {cancelContractRequest.state !== 'fetched' &&
                        cancelContractManualRequest.state !== 'fetched' ? (
                            <ContractTerminationPage />
                        ) : (
                            ''
                        )}
                    </BottomPaddedGridItemS>
                    {cancelContractRequest.state &&
                        !cancelContractManualRequest.state && (
                            <BottomPaddedGridItemM
                                item
                                xs={12}
                                sm={10}
                                md={8}
                                lg={7}
                                xl={5}
                            >
                                <TerminationSuccessPageContainer
                                    showOnlySuccess
                                />
                            </BottomPaddedGridItemM>
                        )}
                    {!cancelContractRequest.state &&
                        cancelContractManualRequest.state && (
                            <BottomPaddedGridItemM
                                item
                                xs={12}
                                sm={10}
                                md={8}
                                lg={7}
                                xl={5}
                            >
                                <ManualTerminationSuccessPageContainer />
                            </BottomPaddedGridItemM>
                        )}
                </CenterGrid>
                <Footer
                    legalLinks={contractTerminationLegalLinks}
                    studioName={selectedStudioName}
                />
            </>
        );
    }
}
