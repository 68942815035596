import { Grid } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

export const BottomPaddedGridItemS = styled(Grid)`
    padding-bottom: 20px;
`;

export const BottomPaddedGridItemM = styled(Grid)`
    padding-bottom: 50px;
`;

export const StyledReCAPTCHA = styled(ReCAPTCHA)`
  padding-bottom: 20px;
`;
