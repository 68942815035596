import { Constructable, external, IComponentOptions, inject, TSDI } from 'tsdi';

function getComponentOptions<T>(
    Dependency: string | Constructable<T>
): IComponentOptions {
    const componentOptions: IComponentOptions | undefined =
        typeof Dependency !== 'string' &&
        Reflect.getMetadata('component:options', Dependency);

    return componentOptions || {};
}

export function injectTSDI<T>(Dependency: string | Constructable<T>): T {
    @external
    class Injector {
        @inject private tsdi!: TSDI;
        public get(): T {
            return this.tsdi.get(Dependency);
        }
    }

    const componentOptions = getComponentOptions(Dependency);

    if (componentOptions.singleton === false) {
        throw new Error(
            'The option { singleton: false } will not work with `injectTSDI`. Please use `useTSDI` when inside a react-component or use the @inject-decorator in classes.'
        );
    }

    return new Injector().get();
}
