import {
    Icon,
    IconButton,
    Snackbar,
    SnackbarContent,
    Typography
} from '@material-ui/core';
import { bind } from 'decko';
import { observer } from 'mobx-react';
import * as React from 'react';
import { external, inject } from 'tsdi';

import { I18n } from '../core/i18n';
import { MessageStore } from './message-store';

@observer
@external
export class Message extends React.Component {
    @inject
    private messageStore!: MessageStore;
    @inject
    private i18n!: I18n;

    public render(): JSX.Element {
        const { translate } = this.i18n;

        return (
            <Snackbar
                open={Boolean(this.messageStore.message)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <SnackbarContent
                    // eslint-disable-next-line react/forbid-component-props
                    style={{ backgroundColor: 'white' }}
                    message={
                        <Typography color={this.messageStore.color}>
                            {translate(this.messageStore.message)}
                        </Typography>
                    }
                    action={
                        <IconButton
                            color="default"
                            component="span"
                            onClick={this.onClick}
                        >
                            <Icon>clear</Icon>
                        </IconButton>
                    }
                />
            </Snackbar>
        );
    }

    @bind
    private onClick(): void {
        this.messageStore.clear();
    }
}
