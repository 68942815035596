import { Button, Grid, Link, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import Markdown from 'react-markdown'
import styled from 'styled-components';

import { CentralLegalLinkDto } from '../../core/api/dtos';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { DefaultDrawer } from '../drawer';
import { Logo } from '../logo';

const StyledButton = styled(Button)`
    .MuiButton-label {
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.54);
        font-weight: 400;
        text-transform: capitalize;
    }
`;

const centralLegalLinkDocumentTypeText = (
    type: CentralLegalLinkDto['centralLegalLinkDocumentType']
) => {
    const { translate } = injectTSDI(I18n);
    switch (type) {
        case 'IMPRINT':
            return translate(
                'legal.imprint'
            );
        case 'PRIVACY':
            return translate(
                'legal.privacy'
            );
        case 'TERMS_AND_CONDITIONS':
            return translate(
                'configuration.legalorganizationunit.legal.links.terms'
            );
        case 'REVOCATION':
            return translate(
                'configuration.legalorganizationunit.legal.links.revocation'
            );
        default:
            return undefined
    }
};

interface FooterProps {
    legalLinks: CentralLegalLinkDto[];
    renderLogo?: boolean;
    studioName?: string;
}

const StyledGrid = styled(Grid)`
    padding-left: 5px;
`;

export const Footer = observer(({ legalLinks, renderLogo, studioName }: FooterProps) => {
    const { translate } = injectTSDI(I18n);

    const [content, setContent]= React.useState('');
    const [header, setHeader]= React.useState('');
    const [open, setOpen]= React.useState(false);

    const filteredLinks = legalLinks.filter((link)=>link.centralLegalLinkDocumentType === 'PRIVACY' || link.centralLegalLinkDocumentType==='IMPRINT' )

    return (
        <Grid container justifyContent='space-around'>
            <StyledGrid container
                item
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                xs={12}
                sm={10}
                md={8}
                lg={7}
                xl={5}
            >
                {renderLogo && (
                    <>
                        <Grid item>
                            <Typography variant="body2" color="textSecondary">
                                {translate(
                                    'common.footer.service.of'
                                )}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Logo src="/images/logo.svg" height={40} />
                        </Grid>
                    </>
                )}
                {studioName && (
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                            <strong>{studioName}</strong>
                        </Typography>
                    </Grid>
                )}
                {filteredLinks.map((link, index)=>{
                    return <Grid item key={`${index}-legal-link-item`}>
                    {link.legalLinkType === 'EXTERNAL_URL' && (
                        <Link variant="body2" href={link.content ?? '.'} underline="hover" color="textSecondary" target='_blank'>
                            {centralLegalLinkDocumentTypeText(link.centralLegalLinkDocumentType)}
                        </Link>
                    )}
                    {link.legalLinkType === 'TEXT' && (
                         <StyledButton onClick={()=> {
                            setContent(link.content ?? '')
                            setHeader(centralLegalLinkDocumentTypeText(link.centralLegalLinkDocumentType) ?? '')
                            setOpen(true)
                        }}>{centralLegalLinkDocumentTypeText(link.centralLegalLinkDocumentType)}</StyledButton>
                    )}
                    </Grid>
                })}

                <DefaultDrawer
                        open={open}
                        anchor='right'
                        onClose={()=> setOpen(false)}
                        header={<h2>{header}</h2>}
                    >
                        <Markdown>{content}</Markdown>
                    </DefaultDrawer>
            </StyledGrid>
        </Grid>
    );
});

