import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { WrappedField } from '../../core/common/forms';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';

interface CancellationEmailTextProps {
    confirmationEmailField: WrappedField<string | undefined>;
    'data-role': string;
}

export const ConfirmationEmailInput = observer((props: CancellationEmailTextProps) => {
    const { translate } = injectTSDI(I18n);
    const { confirmationEmailField } = props;

    return (
        <TextField
            fullWidth
            label={translate(
                'public.content.common.email'
            )}
            variant="outlined"
            helperText={translate(
                'public.content.terminate.contract.email.helper'
            )}
            value={confirmationEmailField.value || ''}
            inputProps={{ 'data-role': props['data-role'] }}
            type="email"
            onChange={event =>
                confirmationEmailField.reset(
                    event.target.value || undefined
                )
            }
        />
    );
});
