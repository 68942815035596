import {Grid, Typography} from '@material-ui/core';
import {observer} from 'mobx-react';
import React from 'react';

import {GetActiveContractsResponseDto} from '../../core/api/dtos';
import {injectTSDI} from '../../core/common/tsdi';
import {I18n} from '../../core/i18n';
import {BottomPaddedGridItemS} from '../styles';
import {ContractView} from './contract-view';

export interface ContractsViewProps {
    contracts: GetActiveContractsResponseDto[];
}

export const ContractsView = observer((props: ContractsViewProps) => {
    const {translate} = injectTSDI(I18n);

    const {contracts} = props;

    if (!contracts || !contracts.length ) {
        return ( <Grid container spacing={10}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    {translate('public.content.terminate.no.contracts.found')}
                </Typography>
            </Grid>
        </Grid>
        );
    }

    return (
        <Grid container>
            <BottomPaddedGridItemS item xs={12}>
                <Typography variant="h6" gutterBottom>
                    {translate('public.content.terminate.contract.found')}
                </Typography>
            </BottomPaddedGridItemS>
            <Grid container item xs={12}>
                {contracts.map(contract => (
                    <BottomPaddedGridItemS
                        item
                        xs={12}
                        key={contract.contractId}
                    >
                        <ContractView contract={contract}/>
                    </BottomPaddedGridItemS>
                ))}
            </Grid>
        </Grid>
    );
});
