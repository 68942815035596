import { bind } from 'decko';
import { computed, Lambda, reaction } from 'mobx';
import { component, initialize, inject } from 'tsdi';
import { wrapRequest } from 'wrap-request';

import { Api } from '../core/api';
import { ConnectApiAdditionalAgreementDto } from '../core/api/dtos';
import { Field, Form } from '../core/common/forms';
import { required } from '../core/common/validation';
import { TokenStore } from '../core/token-store';

@component
export class AdditionalAgreementStore {
    @inject
    private api!: Api;
    @inject
    private tokenStore!: TokenStore;

    public form = new Form({
        agreeChecked: new Field(false, required())
    });

    private agreementRequest = wrapRequest(() =>
        this.api.client.ConnectApiAdditionalAgreementController.getAdditionalAgreement(
            this.tokenStore.customerId
        )
    );

    private updateAgreementRequest = wrapRequest(() =>
        this.api.client.ConnectApiAdditionalAgreementController.setAdditionalAgreement(
            this.tokenStore.customerId
        )
    );

    @bind
    public async onUpdateAgreement(): Promise<void> {
        if (!(await this.form.validate())) {
            return;
        }
        await this.updateAgreementRequest.request();
        await this.agreementRequest.request();
    }

    @computed
    public get agreementData(): ConnectApiAdditionalAgreementDto | undefined {
        return this.agreementRequest.result;
    }

    @computed
    public get loading(): boolean {
        return (
            this.agreementRequest.loading || this.updateAgreementRequest.loading
        );
    }

    @initialize
    protected init(): Lambda {
        return reaction(
            () => this.tokenStore.customerId,
            customerId => customerId && this.agreementRequest.request(),
            { fireImmediately: true }
        );
    }
}
