import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import {
    ScrollToTypography
} from '../../components/scroll-to/scroll-to-typography';
import { Spinner } from '../../components/spinner';
import { ApiError } from '../../core/api/api-error';
import { ConnectApiErrorResponseDto } from '../../core/api/dtos';
import { injectTSDI } from '../../core/common/tsdi';
import { containsErrorWithCode } from '../../core/common/utils';
import { I18n } from '../../core/i18n';
import { ContractTerminationStore } from '../contract-termination-store';
import { ContractsView } from './contracts-view';

export const ContractsViewContainer = observer(() => {
    const { translate } = injectTSDI(I18n);
    const { showContractsRequest, addCancelContractForm } = injectTSDI(
        ContractTerminationStore
    );

    return showContractsRequest.match({
        loading: () => <Spinner />,
        fetched: contracts => {
            contracts.forEach(contract => addCancelContractForm(contract));
            return <ContractsView contracts={contracts} />;
        },
        error: (e: ApiError) => {
            let errorCode = 'public.content.terminate.contract.error';
            const errors = e.errorMessages as ConnectApiErrorResponseDto[];
            if (e.status === 404) {
                errorCode = 'public.content.common.tenant.not.found';
            } else if (errors) {
                if (containsErrorWithCode('ENTITY_NOT_FOUND', errors)) {
                    errorCode = 'public.content.terminate.contract.not.found';
                } else if (
                    containsErrorWithCode('OPERATION_NOT_ALLOWED_FOR_STUDIO', errors)
                ) {
                    errorCode = 'public.content.terminate.contract.no.online.offers';
                } else if (containsErrorWithCode('FEATURE_DISABLED', errors)) {
                    errorCode = 'public.content.terminate.disabled';
                } else if (containsErrorWithCode('STUDIO_NOT_IN_GERMANY', errors)) {
                    errorCode = 'public.content.terminate.contract.studio.not.in.germany';
                }
            }
            return (
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <ScrollToTypography
                            color="error"
                            data-value={errorCode}
                        >
                            {translate(errorCode)}
                        </ScrollToTypography>
                    </Grid>
                </Grid>
            );
        },
        default: () => <></>
    });
});
