import * as moment from 'moment';

import * as dateLib from '@spa-frontend/date-lib';
export {
    DateVariant,
    LocalDate,
    ZonedDateTime,
    assertDateVariant,
    isLocalDate,
    isLocalDateOrZonedDateTime,
    isZonedDateTime,
    parseApiItemToZonedDateTimeOrLocalDate,
    regExTypeDate,
    regExTypeDateTime,
    toLocalDate,
    toRawZonedDateTime,
    toZonedDateTime,
    toZonedDateTimeWithCustomFormat
} from '@spa-frontend/date-lib';

const config = {
    defaultTimezone: '' as string | null
};

export function setDefaultTimezone(timezone: string | null) {
    config.defaultTimezone = timezone;
}

declare module '@spa-frontend/date-lib' {
    /**
     * Converts a date (DateTimeVariant) to ZonedDateTime in the default timezone of the (selected) studio
     */
    function toZonedDateTime(date: undefined): undefined;
    function toZonedDateTime(date: dateLib.DateVariant): ZonedDateTime;
    function toZonedDateTime(
        date: dateLib.DateVariant | undefined
    ): ZonedDateTime | undefined;

    // @ts-ignore
    class ZonedDateTime {
        public static now(): ZonedDateTime;
        /** Reset the timezone */
        public setTZ(timezone?: string): ZonedDateTime;
    }

    // @ts-ignore
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-extraneous-class
    class LocalDate {
        public static now(): LocalDate;
        public isToday(): boolean;
        public isTomorrow(): boolean;
        public isFuture(): boolean;
        public isThisWeek(): boolean;
        public isNextWeek(): boolean;
        public isLastWeek(): boolean;
        public isThisMonth(): boolean;
        public isNextMonth(): boolean;
        public isLastMonth(): boolean;
    }
}

const localDateNow = dateLib.LocalDate.now;
const zonedDateTimeNow = dateLib.ZonedDateTime.now;
const toZonedDateTimeOrg = dateLib.toZonedDateTime;
const parseApiDatesOrg = dateLib.parseApiDates;
const setTZOrg = dateLib.ZonedDateTime.prototype.setTZ;
const isTodayOrg = dateLib.LocalDate.prototype.isToday;
const isTomorrowOrg = dateLib.LocalDate.prototype.isTomorrow;
const isYesterdayOrg = dateLib.LocalDate.prototype.isYesterday;
const isThisWeekOrg = dateLib.LocalDate.prototype.isThisWeek;
const isNextWeekOrg = dateLib.LocalDate.prototype.isNextWeek;
const isLastWeekOrg = dateLib.LocalDate.prototype.isLastWeek;
const isThisMonthOrg = dateLib.LocalDate.prototype.isThisMonth;
const isNextMonthOrg = dateLib.LocalDate.prototype.isNextMonth;
const isLastMonthOrg = dateLib.LocalDate.prototype.isLastMonth;
const isFutureOrg = dateLib.LocalDate.prototype.isFuture;

dateLib.ZonedDateTime.prototype.setTZ = function(timezone?: string) {
    return setTZOrg.call(this, timezone || config.defaultTimezone || '');
};

dateLib.LocalDate.prototype.isToday = function() {
    return isTodayOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.prototype.isTomorrow = function() {
    return isTomorrowOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.prototype.isFuture = function() {
    return isFutureOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.prototype.isYesterday = function() {
    return isYesterdayOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.prototype.isThisWeek = function() {
    return isThisWeekOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.prototype.isNextWeek = function() {
    return isNextWeekOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.prototype.isLastWeek = function() {
    return isLastWeekOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.prototype.isThisMonth = function() {
    return isThisMonthOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.prototype.isNextMonth = function() {
    return isNextMonthOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.prototype.isLastMonth = function() {
    return isLastMonthOrg.call<dateLib.LocalDate, string[], boolean>(
        this,
        config.defaultTimezone || ''
    );
};

dateLib.LocalDate.now = function() {
    return localDateNow(config.defaultTimezone || '');
};

dateLib.ZonedDateTime.now = function() {
    return zonedDateTimeNow(config.defaultTimezone || '');
};

Object.defineProperty(dateLib, 'toZonedDateTime', {
    get() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (date: any) =>
            toZonedDateTimeOrg(date, config.defaultTimezone || '');
    }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseTimezoneWhenNeeded = (item: any): any => {
    if (dateLib.isLocalDateOrZonedDateTime(item)) {
        if (dateLib.isZonedDateTime(item) && !item.timezone) {
            return item.setTZ();
        }
        return item;
    }

    if (Array.isArray(item)) {
        return item.map(parseTimezoneWhenNeeded);
    }

    if (item && typeof item === 'object') {
        return Object.fromEntries(Object.keys(item).map(
            ( key: string) => [key, parseTimezoneWhenNeeded(item[key])]
        ));
    }

    return item;
};

export function parseApiDates(item: unknown, dateProps: string[][]) {
    return parseTimezoneWhenNeeded(parseApiDatesOrg(item, dateProps));
}

export const legacyHelper = {
    convertDateToDefaultTimezone: (date: Date | moment.Moment) => {
        const newDate = moment.isMoment(date) ? date.toDate() : date;

        if (newDate) {
            return dateLib
                .toRawZonedDateTime(newDate)
                .setTZ()
                .toDate();
        }

        return newDate;
    },
    convertDateToLocalTimezone: (date: Date | moment.Moment) => {
        const newDate = moment.isMoment(date) ? date.toDate() : date;

        return dateLib
            .toZonedDateTime(newDate)
            .setLocalTZ()
            .toDate();
    }
};
