export const required = <T = unknown>(
    error = 'commons.validation.required'
) => {
    return (value: T) => {
        if (typeof value === 'number') {
            return isNaN(value) ? error : false;
        }

        return value ? false : error;
    };
};

export const requiredIf = <T = unknown>(condition: () => boolean, error?: string) => {
    return (value: T) => {
        if (condition()) {
            return required(error)(value);
        }
        return false;
    };
};
