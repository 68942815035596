import { action, computed, observable, reaction } from 'mobx';
import { component, initialize, inject } from 'tsdi';

import { LocalDate, ZonedDateTime } from '@spa-frontend/date-lib';

import {
    MultiBrandPlaceholder,
    multiBrandPlaceholderResolver
} from './brand/placeholder';
import { MultiBrandStore } from './brand/store';
import { isDev } from './common/environments';
import { getBrowserLocale } from './common/get-browser-locale';
import { injectTSDI } from './common/tsdi';
import { getEnvironment } from './endpoint';
import { UrlStore } from './url-store';

const FALLBACK_LOCALE: Locale = 'en-GB';
const NO_BREAK_SPACE_CHAR = '\u00A0';
export const SUPPORTED_LOCALES_WITH_FILE_NAME = {
    'de-DE': 'de',
    'en-GB': 'en',
    'es-ES': 'es',
    'it-IT': 'it',
    'fr-FR': 'fr',
    'nl-NL': 'nl',
    'cs-CZ': 'cs',
    'sl-SI': 'sl',
    'hu-HU': 'hu',
    'ro-RO': 'ro'
} as const;

export type Locale = keyof typeof SUPPORTED_LOCALES_WITH_FILE_NAME;

interface FormatDateOptions {
    weekday?: Intl.DateTimeFormatOptions['weekday'];
}

@component
export class I18n {
    @inject
    private urlStore!: UrlStore;
    @inject
    private multiBrandStore!: MultiBrandStore;
    @observable
    public currentLocale!: string;
    @observable
    private version = 0;
    @observable
    public formatDateTime: (value?: ZonedDateTime) => string = () => '';
    @observable
    public formatDate: (
        value?: ZonedDateTime | LocalDate,
        options?: FormatDateOptions
    ) => string = () => '';
    @observable
    public canParseDate: () => boolean = () => false;
    @observable
    public formatDateShort: (
        value?: LocalDate | ZonedDateTime
    ) => string = () => '';
    @observable
    public formatTime: (value?: ZonedDateTime) => string = () => '';
    @observable
    public formatCurrency: (value: number) => string = () => '';

    /** translate */
    @observable
    public translate: (
        key: string | null | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...params: any[]
    ) => string = () => '';

    @computed
    private get dateTimeFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    }

    private getDateFormat(options?: FormatDateOptions): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            weekday: options?.weekday,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour12: false
        });
    }

    @computed
    private get dateFormatShort(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            month: '2-digit',
            day: '2-digit',
            hour12: false
        });
    }

    @computed
    private get timeFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    }

    @computed
    private get currencyNumberFormat(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.currentLocale, {
            style: 'currency',
            currency: 'EUR'
        });
    }

    @initialize
    protected init(): void {
        const browserLocale = getBrowserLocale();
        const urlLocale = this.urlStore.parsedLocale;
        const locale = urlLocale || browserLocale;
        this.setCurrentLocale(locale);

        reaction(
            () => ({
                locale: this.currentLocale
            }),
            ({ locale }) => {
                if (locale) {
                    this.loadTranslationBundle(locale);

                    this.formatDateTime = (value?: ZonedDateTime) =>
                        this.isValidDate(value)
                            ? this.dateTimeFormat.format(value.toDate())
                            : '-';

                    this.formatDate = (
                        value?: ZonedDateTime | LocalDate,
                        options?: FormatDateOptions
                    ) =>
                        this.isValidDate(value)
                            ? this.getDateFormat(options).format(value.toDate())
                            : '-';

                    this.canParseDate = () =>
                        Boolean(this.getDateFormat().formatToParts);

                    this.formatDateShort = (
                        value?: LocalDate | ZonedDateTime
                    ) =>
                        this.isValidDate(value)
                            ? this.dateFormatShort.format(value.toDate())
                            : '-';

                    this.formatTime = (value?: ZonedDateTime) =>
                        this.isValidDate(value)
                            ? this.timeFormat.format(value.toDate())
                            : '-';

                    this.formatCurrency = (value: number) =>
                        this.currencyNumberFormat.format(value);
                }
            },
            {
                name: 'I18n#init',
                fireImmediately: true
            }
        );
    }

    private isValidDate(
        date?: ZonedDateTime | LocalDate | null
    ): date is ZonedDateTime | LocalDate {
        return date?.isValid() ?? false;
    }

    @action
    public setCurrentLocale(locale: string | undefined) {
        if (locale) {
            this.currentLocale = locale;
            return;
        }
        this.currentLocale = FALLBACK_LOCALE;
    }

    @computed
    private get translationResourceFileName() {
        return SUPPORTED_LOCALES_WITH_FILE_NAME[this.localeForTranslation];
    }

    private isLanguageSupported(locale: string): boolean {
        return Boolean(this.getSupportedLocale(locale));
    }

    private getSupportedLocale(locale: string): Locale | undefined {
        const [language] = locale.split('-');
        return this.supportedLocales.find(supportedLocale =>
            supportedLocale.startsWith(language)
        );
    }

    @computed
    public get localeForTranslation(): Locale {
        if (this.isLanguageSupported(this.currentLocale)) {
            return this.getSupportedLocale(this.currentLocale) as Locale;
        }
        return FALLBACK_LOCALE;
    }

    @computed
    private get supportedLocales(): Locale[] {
        return Object.keys(SUPPORTED_LOCALES_WITH_FILE_NAME) as Locale[];
    }

    private getBrandedVariant(key: string, source: { [key: string]: string }) {
        const { brandType } = this.multiBrandStore;

        const potentialBrandedVariantKey = `${key}.branded.${brandType.brandName}`;
        const brandedVariant = source[potentialBrandedVariantKey];

        if (!brandedVariant) {
            return;
        }
        return brandedVariant;
    }

    private async loadTranslationBundle(localeParam: string): Promise<void> {
        if (typeof localeParam !== 'string') {
            return;
        }

        const source: { [key: string]: string } = await (
            await fetch(
                `https://intl.sportalliance.com/ml-public-content-pigeon/${this.translationResourceFileName}.json`
            )
        ).json();

        const translate = (
            key: string | null | undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            params: any
        ): string => {
            if (!key) {
                if (isDev(getEnvironment())) {
                    console.warn('no i18n-key assigned');
                    return 'no i18n-key assigned';
                }
                return '';
            }
            const translated =
                this.getBrandedVariant(key, source) ?? source[key];
            if (!translated) {
                if (isDev(getEnvironment())) {
                    const p = params
                        ? `${NO_BREAK_SPACE_CHAR}[${Object.values(
                              params || {}
                          ).join(`,${NO_BREAK_SPACE_CHAR}`)}]`
                        : '';
                    return `i18n${NO_BREAK_SPACE_CHAR}not${NO_BREAK_SPACE_CHAR}found:${NO_BREAK_SPACE_CHAR}${key}${p}`;
                }
                return key.toString();
            }

            return replacePlaceHolder(translated, params);
        };

        this.translate = this.wrap(translate);

        this.version++;
    }

    @computed
    public get translationsReady(): boolean {
        return this.version > 0;
    }

    private wrap(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fn: (key: string | null | undefined, ...params: any[]) => string
    ): (
        key: string | null | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...params: any[]
    ) => string {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const convertParams = (params: any[]) => {
            // note: allowed values for params are
            // [ 'value1', 'value2', 'value3' ] and
            // [ { '0': 'value1', '1': 'value2', '2': 'value3' } ]
            // params as string-array will be transformed to the latter syntax.
            if (params[0] !== undefined && typeof params[0] !== 'object') {
                return [
                    params.reduce(
                        (
                            memo: { [key: string]: string | number },
                            param: string | number,
                            i: number
                        ) => {
                            memo[`${i}`] = param;
                            return memo;
                        },
                        {}
                    )
                ];
            }
            return params;
        };

        return (key, ...params) => {
            if (key === null || key === undefined) {
                return '';
            }

            try {
                const value = fn(key, ...convertParams(params));
                if (key === value) {
                    console.warn(
                        `%ci18n missing: %c${value}`,
                        'color:#94999d',
                        'color:#000000'
                    );
                }
                return value;
            } catch (e) {
                console.warn((e as Error).message);
                return '';
            }
        };
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function replacePlaceHolder(translated: string, params: any): string {
    const { brandType } = injectTSDI(MultiBrandStore);

    const brandReplacedTranslation = Object.values(
        MultiBrandPlaceholder
    ).reduce((acc, placeholder) => {
        const replacementValue = multiBrandPlaceholderResolver[placeholder](
            brandType
        );
        return acc.split(placeholder).join(replacementValue);
    }, translated);

    if (!params) {
        return brandReplacedTranslation;
    }

    return brandReplacedTranslation
        .replace(/\${/g, '___dollar___bracket___')
        .replace(/{\w}/g, v => params[v[1]])
        .replace(/___dollar___bracket___/g, '${');
}
