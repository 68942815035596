import { Button, Card, Grid, Icon, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { CancelContractResponseDto } from '../../core/api/dtos';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { StudioInformation } from '../components/studio-information';
import { ContractTerminationStore } from '../contract-termination-store';
import { BottomPaddedGridItemS } from '../styles';

const IconWrapper = styled.div`
    font-size: 120px;
    display: flex;
    align-items: center;
    color: green;
    padding: 32px 0 32px 0;
`;

const TopPaddedGridItemS = styled(Grid)`
    padding-top: 20px;
`;

const PaddedCard = styled(Card)`
    padding: 16px;
`;

export interface TerminationSuccessProps {
    contract?: CancelContractResponseDto;
}

export const TerminationSuccessPage = observer(
    (props: TerminationSuccessProps) => {
        const { formatDate, formatDateTime, translate } = injectTSDI(I18n);
        const studioPublicDto = injectTSDI(ContractTerminationStore).getStudio(
            props.contract?.studioId
        )?.studioPublicDto;

        const emailSentMessage = (
            <TopPaddedGridItemS item xs={12}>
                <Typography data-role="termination-success-email">
                    {translate(
                        'public.content.terminate.contract.confirmation.email',
                        props.contract?.confirmationEmail
                    )}
                </Typography>
            </TopPaddedGridItemS>
        );

        const AdditionalInformation = () => (
            <>
                <BottomPaddedGridItemS item xs={7} md={5}>
                    <Typography>
                        {translate(
                            'public.content.terminate.contract.additional.info'
                        )}
                    </Typography>
                </BottomPaddedGridItemS>
                <BottomPaddedGridItemS item xs={5} md={7}>
                    <Typography
                        data-role="termination-success-additional-information">
                        {props.contract?.additionalInformation}
                    </Typography>
                </BottomPaddedGridItemS>
            </>
        );

        return (
            <Grid container>
                <BottomPaddedGridItemS item xs={12}>
                    <PaddedCard variant="outlined">
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid container item xs={12} sm={9}>
                                <BottomPaddedGridItemS item xs={12}>
                                    <Typography
                                        variant="h5"
                                        data-role="termination-success-header"
                                    >
                                        {translate(
                                            'public.content.terminate.contract.success'
                                        )}
                                    </Typography>
                                </BottomPaddedGridItemS>
                                <Grid container item xs={12}>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.firstname'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="termination-success-firstname"
                                        >
                                            {props.contract?.firstname}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.lastname'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="termination-success-lastname">
                                            {props.contract?.lastname}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.dob'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="termination-success-date-of-birth">
                                            {formatDate(
                                                props.contract?.dateOfBirth
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.memberid'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="termination-success-customer-number">
                                            {props.contract?.customerNumber}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.studio'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS
                                        container
                                        item
                                        xs={5}
                                        md={7}
                                    >
                                        <StudioInformation
                                            studioPublicDto={studioPublicDto}
                                        />
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.terminate.contract.type'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography>
                                            {props.contract?.cancellationType === 'ORDINARY_CANCELLATION'
                                                ?
                                                <span
                                                    data-role="termination-success-ordinary">{translate('public.content.terminate.contract.type.ordinary')}</span>
                                                :
                                                <span
                                                    data-role="termination-success-extraordinary">{translate('public.content.terminate.contract.type.extraordinary')}</span>}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.terminate.contract.cancellation.reason.label'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="termination-success-reason">
                                            {props.contract?.cancelationReason?.name}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.contract'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="termination-success-contract-name">
                                            {props.contract?.contractName}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.terminate.contract.terminated.by'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="termination-success-cancellation-date">
                                            {formatDate(
                                                props.contract?.cancellationDate
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    {props.contract?.cancellationType === 'EXTRAORDINARY_CANCELLATION' &&
                                        <AdditionalInformation />}
                                    <Grid item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.terminate.contract.terminated.on'
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} md={7}>
                                        <Typography
                                            data-role="termination-success-received-date">
                                            {formatDateTime(
                                                props.contract?.cancellationReceivedDateTime
                                            )}
                                        </Typography>
                                    </Grid>
                                    {props.contract?.confirmationEmail
                                        ? emailSentMessage
                                        : ''}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                sm={3}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <IconWrapper>
                                    <Icon fontSize="inherit">check_circle</Icon>
                                </IconWrapper>
                            </Grid>
                        </Grid>
                    </PaddedCard>
                </BottomPaddedGridItemS>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={window.print}
                    >
                        {translate('public.content.common.print')}
                    </Button>
                </Grid>
            </Grid>
        );
    }
);
