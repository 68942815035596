import * as React from 'react';

import { CenterWrapper } from '../../core/styles';
import { Spinner } from '../spinner';

export class LoadingPage extends React.Component {

    public render(): JSX.Element {
        return (
            <CenterWrapper>
                <Spinner size={50} />
            </CenterWrapper>
        );
    }
}
