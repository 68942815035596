import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Icon,
    Typography} from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { external, inject } from 'tsdi';

import { I18n } from '../core/i18n';

const HeightGrid = styled(Grid)`
    height: 90%;
    transition: background-color 1000ms linear;
    background-color: ${(props: { bgcolor: string }) => props.bgcolor};
`;

const StyledSaveIcon = styled(Icon)`
    margin-right: 5px;
    text-align: center;
    color: green;
`;

export interface EmailVerificationProps {
    verified?: boolean;
    studioName?: string | null;
    email?: string | null;
}

@observer
@external
export class EmailVerification extends React.Component<EmailVerificationProps> {
    @inject
    private i18n!: I18n;

    public render(): JSX.Element | null {
        return (
            <HeightGrid
                bgcolor="white"
                container
                justify="center"
                alignItems="center"
            >
                <Card>
                    <CardHeader
                        title={this.i18n.translate(
                            'public.content.common.thankyou'
                        )}
                        titleTypographyProps={{
                            align: 'center',
                            variant: 'h5'
                        }}
                    />
                    <CardContent>
                        <Typography align="center">
                            <StyledSaveIcon>done_outline</StyledSaveIcon>
                        </Typography>
                        {this.props.email && (
                            <Typography
                                variant="h5"
                                gutterBottom
                                align="center"
                            >
                                {this.props.email}
                            </Typography>
                        )}
                        {(this.props.verified && this.renderSuccess()) ||
                            this.renderReject()}
                        {this.renderGreetings()}
                    </CardContent>
                </Card>
            </HeightGrid>
        );
    }

    private renderSuccess(): JSX.Element {
        return (
            <Typography variant="h5" gutterBottom align="center">
                {this.i18n.translate(
                    'public.content.email.verification.success'
                )}
            </Typography>
        );
    }

    private renderReject(): JSX.Element {
        return (
            <Typography variant="h5" gutterBottom align="center">
                {this.i18n.translate(
                    'public.content.email.verification.reject'
                )}
            </Typography>
        );
    }

    private renderGreetings(): JSX.Element {
        return (
            <>
                <Typography variant="subtitle1" gutterBottom align="center">
                    {this.i18n.translate('public.content.email.greetings')}
                </Typography>
                {this.props.studioName && (
                    <Typography variant="h5" gutterBottom align="center">
                        {this.props.studioName}
                    </Typography>
                )}
            </>
        );
    }
}
