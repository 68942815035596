import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { LocalDate } from '../core/api/date';
import { getSitekey } from '../core/common/recaptcha';
import { injectTSDI } from '../core/common/tsdi';
import { I18n } from '../core/i18n';
import { CancellationStudioSelect } from './components/cancellation-studio-select';
import { ContractTerminationStore } from './contract-termination-store';
import { StyledReCAPTCHA } from './styles';

export const MemberDataForm = observer(() => {
    const recaptchaRef: React.RefObject<ReCAPTCHA> = React.createRef<
        ReCAPTCHA
    >();
    const { translate } = injectTSDI(I18n);
    const contractTerminationStore = injectTSDI(ContractTerminationStore);
    const {
        firstname,
        lastname,
        dateOfBirth,
        customerNumber
    } = contractTerminationStore.memberDataForm.wrappedField;

    return (
        <Card variant="outlined">
            <CardHeader
                title={translate('public.content.terminate.contract.leave')}
                titleTypographyProps={{
                    variant: 'h5'
                }}
            />
            <CardContent>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography>
                            {translate(
                                'public.content.terminate.contract.info'
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            {translate('public.content.common.studio.which')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CancellationStudioSelect />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={3}
                        >
                            <Grid item xs={6} sm={4}>
                                <TextField
                                    fullWidth
                                    required
                                    value={firstname.value}
                                    onChange={event =>
                                        firstname.reset(event.target.value)
                                    }
                                    error={Boolean(firstname.error)}
                                    helperText={translate(firstname.error)}
                                    label={translate(
                                        'public.content.common.firstname'
                                    )}
                                    variant="outlined"
                                    inputProps={{
                                        'data-role': 'firstname'
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <TextField
                                    fullWidth
                                    required
                                    label={translate(
                                        'public.content.common.lastname'
                                    )}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={lastname.value}
                                    onChange={event =>
                                        lastname.reset(event.target.value)
                                    }
                                    error={Boolean(lastname.error)}
                                    helperText={translate(lastname.error)}
                                    inputProps={{ 'data-role': 'lastname' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    required
                                    label={translate(
                                        'public.content.common.dob'
                                    )}
                                    variant="outlined"
                                    type="date"
                                    inputProps={{
                                        max: LocalDate.now()
                                            .subDays(1)
                                            .toString(),
                                        'data-role': 'date-of-birth'
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={dateOfBirth.value.toString()}
                                    onChange={event =>
                                        contractTerminationStore.updateDateIfValid(
                                            event,
                                            dateOfBirth
                                        )
                                    }
                                    error={Boolean(dateOfBirth.error)}
                                    helperText={translate(dateOfBirth.error)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={translate(
                                        'public.content.common.memberid'
                                    )}
                                    variant="outlined"
                                    value={customerNumber.value || ''}
                                    onChange={event =>
                                        customerNumber.reset(
                                            event.target.value || undefined
                                        )
                                    }
                                    inputProps={{
                                        'data-role': 'member-id'
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={translate(
                                        'public.content.terminate.contract.show.contracts.info'
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    data-role="submit-member-data"
                                    disabled={Boolean(
                                        !customerNumber.value ||
                                            !customerNumber.value.trim()
                                    )}
                                    onClick={() =>
                                        contractTerminationStore.handleMemberDataSubmit(
                                            recaptchaRef
                                        )
                                    }
                                >
                                    {translate(
                                        'public.content.terminate.contract.show.contracts'
                                    )}
                                </Button>
                            </Grid>
                            <StyledReCAPTCHA
                                sitekey={getSitekey()}
                                ref={recaptchaRef}
                                size="invisible"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
});
