import { computed, observable, reaction } from 'mobx';
import { component, initialize, inject } from 'tsdi';
import { wrapRequest } from 'wrap-request';

import { Api } from './api';
import { BrandName } from './brand/store';
import { UrlStore } from './url-store';

@component
export class TenantInfoStore {
    @inject
    private api!: Api;

    @inject
    private urlStore!: UrlStore;

    @initialize
    protected init() {
        reaction(
            () => this.urlStore.tenant,
            (tenant?: string | null) =>
                tenant && this.tenantInfoRequest.request(),
            { fireImmediately: true }
        );
    }

    @observable
    private tenantInfoRequest = wrapRequest(() =>
        this.api.client.ConnectApiTenantController.getTenantInfo()
    );

    @computed
    public get tenantMultiBrandType(): BrandName | undefined {
        if (!this.tenantInfoRequest.result) {
            return;
        }
        return this.tenantInfoRequest.result?.tenantMultiBrandType ===
            'PERFECT_GYM'
            ? 'perfectgym'
            : 'magicline';
    }
}
