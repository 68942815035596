import { action, observable } from 'mobx';
import { component, initialize, inject } from 'tsdi';

import { MessageStore } from '../message/message-store';
import { Api } from './api';
import { AuthDto } from './api/dtos';
import { UrlStore } from './url-store';

@component
export class TokenStore {
    @inject
    private api!: Api;
    @inject
    private messageStore!: MessageStore;
    @inject
    private urlStore!: UrlStore;

    @observable
    public customerId!: number;

    @observable
    public studioId!: number;

    @initialize
    public async init(): Promise<void> {
        await this.grantToken();
    }

    @action
    public async grantToken(): Promise<void> {
        try {
            const auth = await this.getAuth();

            this.api.token = auth.accessToken;
            this.customerId = auth.customerId || 0;
            this.studioId = auth.studioId || 0;
        } catch (e) {
            this.messageStore.displayError(e);
        }
    }

    private getAuth(): Promise<AuthDto> {
        const { encryptedCustomerId, hash, customerUuid } = this.urlStore;

        if (encryptedCustomerId && hash) {
            return this.api.client.ConnectApiAuthenticationController.grantAccess(
                encryptedCustomerId,
                { hash }
            );
        }
        if (customerUuid) {
            return this.api.client.ConnectApiAuthenticationController.grantAccessUuid(
                customerUuid
            );
        }
        throw new Error('REQUIRED_PARAMETER_MISSING');
    }
}
