export enum Environment {
    PRODUCTION = 'production',
    REF = 'ref',
    STAGE = 'stage',
    DEV = 'dev',
    LOCAL = 'local'
}

export function isProd(environment: Environment): boolean {
    // prettier-ignore
    return [
        Environment.PRODUCTION,
        Environment.REF
    ].includes(environment);
}

export function isStage(environment: Environment): boolean {
    // prettier-ignore
    return [
        Environment.STAGE
    ].includes(environment);
}

export function isDev(environment: Environment): boolean {
    // prettier-ignore
    return [
        Environment.DEV,
        Environment.LOCAL
    ].includes(environment);
}

export function isValidEnvironment(environment: Environment): boolean {
    return isProd(environment) || isStage(environment) || isDev(environment);
}
