import { observable, reaction } from 'mobx';
import { component, initialize, inject } from 'tsdi';

import { TenantInfoStore } from '../tenant-info-store';
import { TenantApplicationBrandType } from './brand-type';

export const BRANDS = ['magicline', 'perfectgym'] as const;
export type BrandName = typeof BRANDS[number];

@component
export class MultiBrandStore {
    @inject
    private tenantInfoStore!: TenantInfoStore;

    @observable
    public brandType = TenantApplicationBrandType.MAGICLINE;

    @initialize
    protected init() {
        reaction(
            () => this.tenantInfoStore.tenantMultiBrandType,
            brandName => {
                if (brandName) {
                    this.brandType = this.getBrandTypeByBrandName(brandName);
                }
            },
            { fireImmediately: true }
        );
    }

    private getBrandTypeByBrandName(
        brand: BrandName | undefined | null
    ): TenantApplicationBrandType {
        switch (brand) {
            case 'perfectgym':
                return TenantApplicationBrandType.PERFECTGYM;
            case 'magicline':
                return TenantApplicationBrandType.MAGICLINE;
            default:
                return TenantApplicationBrandType.MAGICLINE;
        }
    }
}
