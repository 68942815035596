import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { CancelationReasonDto, CentralLegalLinkDto } from '../core/api/dtos';
import { injectTSDI } from '../core/common/tsdi';
import { ContractTerminationStore } from './contract-termination-store';
import { ContractTerminationManualForm } from './contracts/contract-termination-manual-form';
import { ContractsViewContainer } from './contracts/contracts-view-container';
import { MemberDataForm } from './member-data-form';
import { BottomPaddedGridItemS } from './styles';

export interface StudioInfoDto {
    cancelationReasons: CancelationReasonDto[];
    legalLinks: CentralLegalLinkDto[]
}

export const ContractTerminationPage = observer(() => {
    const { hasContracts } = injectTSDI(ContractTerminationStore);
    return (
        <Grid container>
            <BottomPaddedGridItemS item xs={12}>
                <MemberDataForm />
            </BottomPaddedGridItemS>

            <BottomPaddedGridItemS item xs={12}>
                <ContractsViewContainer />
            </BottomPaddedGridItemS>
            {!hasContracts && (
                <Grid item xs={12}>
                    <ContractTerminationManualForm />
                </Grid>
            )}
        </Grid>
    );
});
