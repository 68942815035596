import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import { Colors } from '../../core/styles';

export interface SpinnerProps {
    color?: string;
    size?: number;
}

const spinning = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const SpinnerComponent = styled.div<SpinnerProps>`
    position: relative;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    pointer-events: none;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        animation: ${spinning} 500ms infinite linear;
        border-radius: 50%;
        content: '';
        display: block;
        height: ${props => props.size}px;
        width: ${props => props.size}px;
        z-index: 1;
        border: 0.2rem solid ${props => props.color};
        border-right-color: transparent;
        border-top-color: transparent;
        box-sizing: border-box;
    }
`;

export class Spinner extends React.PureComponent<SpinnerProps> {
    public static defaultProps = {
        color: Colors.blue,
        size: 25
    };

    public render(): JSX.Element {
        const { color, size } = this.props;
        return <SpinnerComponent color={color} size={size} />;
    }
}
