import { action, observable } from 'mobx';
import { get } from 'ts-get';
import { component } from 'tsdi';

export type ErrorCodeEnum =
    | 'ENTITY_NOT_FOUND'
    | 'CONFLICT'
    | 'BAD_REQUEST'
    | 'REQUIRED_PARAMETER_MISSING'
    | 'VALIDATION_FAILED'
    | 'FORBIDDEN'
    | 'FEATURE_DISABLED';

@component
export class MessageStore {
    @observable
    public message?: string;
    @observable
    public messageCode?: ErrorCodeEnum;
    @observable
    public color?: 'primary' | 'error';

    @action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public displayError(e: any): void {
        const messageCode = this.getErrorCodeEnum(e);

        if (messageCode) {
            this.setErrorMessage(this.getMessageText(messageCode));
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getErrorCodeEnum(e: any): ErrorCodeEnum {
        return typeof e === 'string'
            ? (e as ErrorCodeEnum)
            : (get(e, it => it._responses[0].errorCodes[0]) as ErrorCodeEnum);
    }

    @action
    public setErrorMessage(message: string): void {
        this.message = message;
        this.color = 'error';
    }

    @action
    public clear(): void {
        this.message = undefined;
    }

    private getMessageText(messageCode: ErrorCodeEnum): string {
        switch (messageCode) {
            case 'VALIDATION_FAILED':
                return 'public.content.error.url';
            case 'REQUIRED_PARAMETER_MISSING':
                return 'public.content.error.url';
            case 'FORBIDDEN':
                return 'public.content.error.generic';
            default:
                return 'public.content.error.save';
        }
    }
}
