import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';

export class ScrollToTypography extends React.Component<TypographyProps> {
    private typographyRef = React.createRef<HTMLSpanElement>();

    public componentDidMount(): void {
        this.typographyRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    public render(): React.ReactNode {
        return (
            <Typography
                {...this.props}
                ref={this.typographyRef}
            >
                {this.props.children}
            </Typography>
        );
    }
}
