import { getEnvironment } from '../endpoint';
import { Environment } from './environments';

export function getSitekey(): string {
    switch (getEnvironment()) {
        case Environment.LOCAL:
        case Environment.DEV:
            return '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
        case Environment.STAGE:
        case Environment.REF:
        case Environment.PRODUCTION:
            return '6Ld5qKcgAAAAAMoywArOgIrC0lQ7NpYUTsF92PIC';
    }
}
