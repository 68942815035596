import { Drawer } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

const ChildrenWrapper = styled.div`
    padding: 10px;
    min-width: 200px;
    max-width: calc(50vw - 25px);
`
interface DefultDrawerProps {
    anchor?: 'left'| 'right'| 'top'|'bottom';
    open?: boolean;
    onOpen?(): void;
    onClose?(): void;
    children: React.ReactNode;
    header?: React.ReactNode;
}

export const DefaultDrawer = observer((props: DefultDrawerProps)=> {
    const { anchor, open = false, onClose, children, header } = props;
    return  <Drawer
    anchor={anchor}
    open={open}
    onClose={onClose}
  >
    <ChildrenWrapper>
        {header && (header)}
        {children}
    </ChildrenWrapper>
  </Drawer>
})
