import { MenuItem, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { ContractTerminationStore } from '../contract-termination-store';

export const CancellationStudioSelect = observer(() => {
    const { translate } = injectTSDI(I18n);
    const {
        studios,
        cancelContractManualForm,
        getStudioInfo
    } = injectTSDI(ContractTerminationStore);
    const {
        studioId,
        cancelationReasonId
    } = cancelContractManualForm.wrappedField;

    if (!studios || !studios.length) {
        return null;
    }

    const onStudioSelect = (selectedStudioId: number) => {
        studioId.reset(selectedStudioId);
        cancelationReasonId.reset(undefined);
        getStudioInfo.request(selectedStudioId);
    };

    return (
        <TextField
            fullWidth
            required
            select
            value={studioId.value ?? ''}
            data-role="contract-termination-studios"
            variant="outlined"
            label={translate('public.content.common.studio')}
            onChange={event => onStudioSelect(parseInt(event.target.value, 10))}
            error={Boolean(studioId.error)}
            helperText={translate(studioId.error)}
        >
            {studios?.map(studio => (
                <MenuItem
                    key={studio.id}
                    value={studio.id}
                    data-role="contract-termination-studio"
                >
                    <span data-role="contract-termination-studio-name">
                        {studio.studioPublicDto?.name}
                    </span>
                    <span>&nbsp;({studio.studioPublicDto?.city})</span>
                </MenuItem>
            ))}
        </TextField>
    );
});
