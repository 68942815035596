import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { Spinner } from '../../components/spinner';
import { ApiError } from '../../core/api/api-error';
import { ConnectApiErrorResponseDto } from '../../core/api/dtos';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { ContractTerminationStore } from '../contract-termination-store';
import { TerminationSuccessPage } from './termination-success-page';

interface TerminationSuccessPageContainerProps {
    showOnlySuccess?: boolean;
}

export const TerminationSuccessPageContainer = observer(
    (props: TerminationSuccessPageContainerProps) => {
        const { translate } = injectTSDI(I18n);
        const { cancelContractRequest } = injectTSDI(ContractTerminationStore);

        if (props.showOnlySuccess) {
            return cancelContractRequest.match({
                fetched: contract => (
                    <TerminationSuccessPage contract={contract} />
                ),
                default: () => <></>
            });
        }

        return cancelContractRequest.match({
            loading: () => <Spinner />,
            error: (e: ApiError) => {
                let errorMessage = e.message;
                const errors = e.errorMessages as ConnectApiErrorResponseDto[];
                if (
                    errors && errors.some(
                        error =>
                            error.errorCodes &&
                            error.errorCodes.includes('SERVER_ERROR')
                    )
                ) {
                    errorMessage = translate(
                        'public.content.terminate.contract.error'
                    );
                }
                return <Typography color="error">{errorMessage}</Typography>;
            },
            default: () => <></>
        });
    }
);
