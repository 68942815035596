import { bind } from 'decko';
import { observer } from 'mobx-react';
import * as React from 'react'; // preact-compat
import styled from 'styled-components';
import { external, inject } from 'tsdi';

import { I18n } from '../core/i18n';
import { Colors, defaultFont, MEDIUM } from '../core/styles';
import { CommMatrixStore } from './commmatrix-store';

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const StyledButton = styled.button`
    ${defaultFont()};
    text-transform: uppercase;
    border: none;
    padding: 16px 22px;
    background: ${Colors.blue};
    color: ${Colors.white};
    cursor: pointer;

    &:disabled {
        background: ${Colors.disabledBackground};
        color: ${Colors.disabledText};
        cursor: default;
    }

    width: 100%;

    @media (min-width: ${MEDIUM}px) {
        width: auto;
    }
`;

@observer
@external
export class SubmitButton extends React.Component {
    @inject
    private i18n!: I18n;
    @inject
    private dataStore!: CommMatrixStore;

    public render(): JSX.Element {
        const { translate } = this.i18n;
        const { commMatrixModified } = this.dataStore;

        return (
            <StyledWrapper>
                <StyledButton
                    onClick={this.onClick}
                    disabled={!commMatrixModified}
                    data-role="submit-button"
                >
                    {translate('public.content.communication.settings.save')}
                </StyledButton>
            </StyledWrapper>
        );
    }
    @bind
    private async onClick(): Promise<void> {
        await this.dataStore.updateCommatrix();
    }
}
