import { createTheme } from '@material-ui/core';
// import { createTheme } from '@material-ui/core/styles'
import { blue, grey, red } from '@material-ui/core/colors';
import { computed, observable } from 'mobx';
import { component } from 'tsdi';

const defaultTheme = createTheme({
    palette: {
        primary: blue,
        secondary: grey,
        error: red
    }
});

@component
export class ThemeStore {
    @observable
    public _theme = defaultTheme;

    @computed
    public get theme() {
        return this._theme;
    }
}
