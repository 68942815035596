import { Box, Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { GetActiveContractsResponseDto } from '../../core/api/dtos';
import { getSitekey } from '../../core/common/recaptcha';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import {
    CancellationAdditionalInfoText
} from '../components/cancellation-additional-info-text';
import { CancellationDatePicker } from '../components/cancellation-date-picker';
import { CancellationDateSelect } from '../components/cancellation-date-select';
import {
    CancellationReasonSelect
} from '../components/cancellation-reason-select';
import { CancellationTypeSelect } from '../components/cancellation-type-select';
import { ConfirmationEmailInput } from '../components/confirmation-email-input';
import { ContractTerminationStore } from '../contract-termination-store';
import { BottomPaddedGridItemS, StyledReCAPTCHA } from '../styles';

export interface ContractTerminationFormProps {
    contract: GetActiveContractsResponseDto;
}

export const ContractTerminationForm = observer(
    (props: ContractTerminationFormProps) => {
        const recaptchaRef = React.createRef<ReCAPTCHA>();
        const { translate } = injectTSDI(I18n);
        const contractTerminationStore = injectTSDI(ContractTerminationStore);

        const cancelContractForm = contractTerminationStore.getCancelContractForm(
            props.contract.contractId
        );

        if (!cancelContractForm || !props.contract.contractId) {
            return <></>;
        }

        const { contractId } = props.contract;

        const {
            confirmationEmail,
            cancelationReasonId,
            cancellationType,
            additionalInformation
        } = cancelContractForm.wrappedField;

        const {
            updateCancellationType,
            extraordinaryCancellationHelperText
        } = contractTerminationStore;

        const cancellationDateInputElement = cancellationType.value === 'EXTRAORDINARY_CANCELLATION'
            ?
            <CancellationDatePicker contract={props.contract} minDate="TODAY" />
            : props.contract.cancellationType === 'FIXED_DATES' ?
                <CancellationDateSelect contract={props.contract} />
                : <CancellationDatePicker contract={props.contract}
                                          minDate="EARLIEST_FOR_CONTRACT" />;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CancellationTypeSelect
                        cancellationTypeField={cancellationType}
                        onChange={event =>
                            updateCancellationType(
                                event,
                                cancellationType
                            )}
                        helperTextExtraordinaryTermination={extraordinaryCancellationHelperText}
                        data-role="contract-termination-type"
                    />
                </Grid>
                <Box width="100%" />
                <BottomPaddedGridItemS item xs={12} sm={6}>
                    {cancellationDateInputElement}
                </BottomPaddedGridItemS>
                <Grid item xs={12} sm={6}>
                    <CancellationReasonSelect
                        cancellationTypeField={cancellationType}
                        cancellationReasonIdField={cancelationReasonId}
                        studioId={props.contract.studioId}
                    />
                </Grid>
                {cancellationType.value === 'EXTRAORDINARY_CANCELLATION'
                    ? <Grid item xs={12}>
                        <CancellationAdditionalInfoText
                            additionalInformationField={additionalInformation}
                            helperText={translate('public.content.terminate.contract.info.extraordinary')}
                            data-role="contract-termination-additional-info"
                        />
                    </Grid>
                    : undefined}
                <BottomPaddedGridItemS item xs={12} sm={6}>
                    <ConfirmationEmailInput
                        confirmationEmailField={confirmationEmail}
                        data-role="confirmation-email"
                    />
                </BottomPaddedGridItemS>
                <Grid item xs={12}>
                    <StyledReCAPTCHA
                        sitekey={getSitekey()}
                        ref={recaptchaRef}
                        size="invisible"
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        data-role="submit-terminate-contract"
                        onClick={() =>
                            contractTerminationStore.handleTerminate(
                                recaptchaRef,
                                contractId
                            )
                        }
                    >
                        {translate('public.content.terminate.contract.now')}
                    </Button>
                </Grid>
            </Grid>
        );
    }
);
