import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../core/styles';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    size: number;
    round: boolean;
    color: string;
    checkmarkColor: string;
    borderColor: string;
    emptyColor: string;
    checked: boolean;
    disabled?: boolean;
    'data-role'?: string;
}

function sizePercent(percent: number): (props: CheckboxProps) => number {
    return (props: CheckboxProps) => props.size * (percent / 100);
}

const StyledCheckbox = styled.div<CheckboxProps>`
    position: relative;
    user-select: none;
    width: ${sizePercent(100)}px;
    height: ${sizePercent(100)}px;

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: solid 1px ${props => props.borderColor};
        background-color: ${props => props.emptyColor};
        transition: background-color 50ms;

        ${props =>
            props.round &&
            css`
                border-radius: 50%;
            `}

        &:hover {
            border-color: ${props => props.color};
        }

        &:after {
            content: '';
            position: absolute;
            display: none;
            left: ${sizePercent(36)}px;
            top: ${sizePercent(25)}px;
            width: ${sizePercent(20)}px;
            height: ${sizePercent(40)}px;
            border: solid ${props => props.checkmarkColor};
            border-width: 0 ${sizePercent(9)}px ${sizePercent(9)}px 0;
            transform: rotate(45deg);
        }

        ${props =>
            props.checked &&
            css`
                background-color: ${(props: CheckboxProps) => props.color};
                border-color: ${props => props.color};

                &:after {
                    display: block;
                }
            `}
    }
`;

export class Checkbox extends React.Component<CheckboxProps> {
    public static defaultProps = {
        size: 25,
        round: false,
        color: Colors.blue,
        checkmarkColor: Colors.white,
        emptyColor: Colors.creamy,
        borderColor: '#e4e4e4'
    };

    public render(): JSX.Element {
        const {
            size,
            round,
            color,
            checkmarkColor,
            emptyColor,
            borderColor,
            checked,
            disabled,
            'data-role': dataRole
        } = this.props;
        const stylingProps = {
            size,
            round,
            color: disabled ? Colors.disabledText : color,
            checkmarkColor,
            emptyColor,
            borderColor,
            checked
        };

        return (
            <StyledCheckbox {...stylingProps}>
                <input type="checkbox" {...this.props} data-role={dataRole} />
                <span />
            </StyledCheckbox>
        );
    }
}
