import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Icon,
    Typography
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { wrapRequest } from 'wrap-request';

import { Spinner } from '../components/spinner';
import { Api } from '../core/api';
import { ApiError } from '../core/api/api-error';
import { injectTSDI } from '../core/common/tsdi';
import { getUrlParams } from '../core/common/utils';

const TopPaddedGrid = styled(Grid)`
    padding-top: 20%;
`;

const confirmCustomerParticipationRequest = wrapRequest((payload: string) =>
    injectTSDI(
        Api
    ).client.ConnectApiPromotionController.confirmCustomerParticipation({
        payload
    })
);

const handleParticipate = () => {
    const payload = getUrlParams(window.location.href).get('payload');
    if (!payload) {
        return;
    }
    void confirmCustomerParticipationRequest.request(payload);
};

export const PromotionTest = observer(() => (
    <TopPaddedGrid container justifyContent="center">
        <Grid item>
            <Card variant="outlined">
                <CardHeader title="Test promotion" />
                <CardContent>
                    <Grid
                        container
                        spacing={5}
                        direction="column"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography>
                                Join now and win cool prizes!
                            </Typography>
                        </Grid>
                        <Grid item>
                            {confirmCustomerParticipationRequest.match({
                                loading: () => <Spinner />,
                                error: (error: ApiError) => (
                                    <Typography color="error">
                                        Error {error.status} (see network for
                                        details)
                                    </Typography>
                                ),
                                fetched: () => (
                                    <Icon color="primary" fontSize="large">
                                        check_circle
                                    </Icon>
                                ),
                                default: () => (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleParticipate}
                                    >
                                        Join now
                                    </Button>
                                )
                            })}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </TopPaddedGrid>
));
