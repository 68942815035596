import './styles/main.css';

import { Bugsnag } from 'bugsnag-js';
import { render } from 'react-dom';
import { TSDI } from 'tsdi';

import { App } from './app';
import { createReactErrorBoundary } from './app/bugsnag';

const tsdi = new TSDI();
tsdi.enableComponentScanner();

const bugsnagClient = tsdi.get<Bugsnag.Client>('bugsnag');

const WrappedApplication = createReactErrorBoundary(bugsnagClient, App);
render(WrappedApplication, document.getElementById('main'));
