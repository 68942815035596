import styled from 'styled-components';

import { defaultText, MEDIUM } from '../core/styles';

export const MatrixContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    position: relative;
`;

export const MatrixGrid = styled.div`
    border-radius: 9px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15), inset 0 1px 0 0 #f5f6f7;
    max-width: 920px;

    margin: 20px 5px 5px;
    padding: 20px 10px;

    @media (min-width: ${MEDIUM}px) {
        margin: 50px 10px 10px;
        padding: 40px 30px;
    }
`;

export const Text = styled.p`
    ${defaultText()};
`;

export const LabelColumn = styled.p`
    ${defaultText()};
    font-weight: 600;
    font-size: 16px;
    text-align: center;
`;

export const LabelRow = styled.p`
    ${defaultText()};
    font-weight: 600;
    font-size: 18px;
`;

export const CheckboxField = styled.label`
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const CheckboxFieldInner = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CheckboxLabel = styled.p`
    ${defaultText()};
    font-weight: 600;
    display: block;
    text-align: center;
    margin: 10px 0 20px;

    @media (min-width: ${MEDIUM}px) {
        display: none;
    }
`;

export const TopicRowWrapper = styled.div`
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${MEDIUM}px) {
        display: flex;
    }
`;

export const ColumnLong = styled.div`
    @media (min-width: ${MEDIUM}px) {
        min-width: auto;
        flex-grow: 1;
    }
`;

export const ColumnShort = styled.div`
    display: inline-block;
    min-width: 18%;

    @media (min-width: ${MEDIUM}px) {
        min-width: 80px;
    }
`;
