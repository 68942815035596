import { TenantApplicationBrandType } from './brand-type';

export enum MultiBrandPlaceholder {
    NAME = '%MultiBrandName%',
    COMPANY_NAME = '%MultiBrandCompanyName%',
    DOMAIN_NAME = '%MultiBrandDomainName%'
}

type BrandPlaceholderMap = {
    [K in MultiBrandPlaceholder]: (brand: TenantApplicationBrandType) => string;
};

export const multiBrandPlaceholderResolver: BrandPlaceholderMap = {
    [MultiBrandPlaceholder.NAME]: (brand: TenantApplicationBrandType) =>
        brand.applicationTitle,
    [MultiBrandPlaceholder.COMPANY_NAME]: (brand: TenantApplicationBrandType) =>
        brand.companyName,
    [MultiBrandPlaceholder.DOMAIN_NAME]: (brand: TenantApplicationBrandType) =>
        brand.domainName
};
