import { Button, Card, Grid, Icon, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { CancelContractManualResponseDto } from '../../core/api/dtos';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { StudioInformation } from '../components/studio-information';
import { ContractTerminationStore } from '../contract-termination-store';
import { BottomPaddedGridItemS } from '../styles';

const IconWrapper = styled.div`
    font-size: 120px;
    display: flex;
    align-items: center;
    color: green;
    padding: 32px 0 32px 0;
`;

const TopPaddedGridItemS = styled(Grid)`
    padding-top: 20px;
`;

const PaddedCard = styled(Card)`
    padding: 16px;
`;

export interface TerminationSuccessProps {
    manualCancellation?: CancelContractManualResponseDto;
}

export const ManualTerminationSuccessPage = observer(
    (props: TerminationSuccessProps) => {
        const { formatDate, formatDateTime, translate } = injectTSDI(I18n);
        const studioPublicDto = injectTSDI(ContractTerminationStore).getStudio(
            props.manualCancellation?.studioId
        )?.studioPublicDto;

        return (
            <Grid container>
                <BottomPaddedGridItemS item xs={12}>
                    <PaddedCard variant="outlined">
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid container item xs={12} sm={9}>
                                <BottomPaddedGridItemS item xs={12}>
                                    <Typography
                                        variant="h5"
                                        data-role="manual-termination-success-header"
                                    >
                                        {translate(
                                            'public.content.terminate.contract.success'
                                        )}
                                    </Typography>
                                </BottomPaddedGridItemS>
                                <BottomPaddedGridItemS item xs={12}>
                                    <Typography variant="subtitle2">
                                        {translate(
                                            'public.content.terminate.contract.associate.warning'
                                        )}
                                    </Typography>
                                </BottomPaddedGridItemS>
                                <Grid container item xs={12}>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.firstname'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="manual-termination-success-firstname">
                                            {
                                                props.manualCancellation
                                                    ?.firstname
                                            }
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.lastname'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="manual-termination-success-lastname">
                                            {props.manualCancellation?.lastname}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.dob'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="manual-termination-success-date-of-birth">
                                            {formatDate(
                                                props.manualCancellation?.dateOfBirth
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.memberid'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="manual-termination-success-customer-number">
                                            {props.manualCancellation?.customerNumber || '-'}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.common.studio'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <StudioInformation
                                            studioPublicDto={studioPublicDto}
                                        />
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.terminate.contract.type'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography>
                                            {props.manualCancellation?.cancellationType === 'ORDINARY_CANCELLATION'
                                                ? <span
                                                    data-role="manual-termination-success-ordinary">
                                                    {translate('public.content.terminate.contract.type.ordinary')}
                                                </span>
                                                : <span
                                                    data-role="manual-termination-success-extraordinary">
                                                    {translate('public.content.terminate.contract.type.extraordinary')}
                                                </span>}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.terminate.contract.cancellation.reason.label'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="manual-termination-success-reason">
                                            {props.manualCancellation?.cancelationReason?.name}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.terminate.contract.terminated.by'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="manual-termination-success-cancellation-date">
                                            {props.manualCancellation?.cancellationDateType === 'ABSOLUTE_CANCELLATION_DATE'
                                                ? formatDate(props.manualCancellation.cancellationDate)
                                                : translate('public.content.terminate.contract.next.possible.cancellation.date')}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.terminate.contract.additional.info'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="manual-termination-success-additional-info">
                                            {props.manualCancellation?.additionalInformation}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={7} md={5}>
                                        <Typography>
                                            {translate(
                                                'public.content.terminate.contract.terminated.on'
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    <BottomPaddedGridItemS item xs={5} md={7}>
                                        <Typography
                                            data-role="manual-termination-success-received-date">
                                            {formatDateTime(
                                                props.manualCancellation?.requestReceivedDate
                                            )}
                                        </Typography>
                                    </BottomPaddedGridItemS>
                                    {props.manualCancellation
                                        ?.confirmationEmail && (
                                        <TopPaddedGridItemS item xs={12}>
                                            <Typography
                                                data-role="manual-termination-success-email">
                                                {translate(
                                                    'public.content.terminate.contract.confirmation.email',
                                                    props.manualCancellation
                                                        .confirmationEmail
                                                )}
                                            </Typography>
                                        </TopPaddedGridItemS>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                sm={3}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <IconWrapper>
                                    <Icon fontSize="inherit">check_circle</Icon>
                                </IconWrapper>
                            </Grid>
                        </Grid>
                    </PaddedCard>
                </BottomPaddedGridItemS>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={window.print}
                    >
                        {translate('public.content.common.print')}
                    </Button>
                </Grid>
            </Grid>
        );
    }
);
