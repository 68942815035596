import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    WithStyles,
    withStyles
} from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { red } from '@material-ui/core/colors';
import FormHelperText from '@material-ui/core/FormHelperText';
import { GridProps } from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import * as React from 'react';

import { transform } from '../core/common/case-utils';
import { WrappedField } from '../core/common/forms';
import { injectTSDI } from '../core/common/tsdi';
import { I18n } from '../core/i18n';

const checkBoxStyles = {
    error: {
        color: red[600]
    },
    helperText: {
        margin: '0 0 0 34px'
    },
    checked: {}
};

export interface DefaultCheckboxProps extends CheckboxProps {
    field: WrappedField<boolean> | undefined;
    disabled?: boolean;
    label: string;
    labelArgs?: (string | undefined)[];
    grid?: GridProps;
    'data-role'?: string;
}

type DefaultCheckboxPropsWithStyles = DefaultCheckboxProps &
    WithStyles<typeof checkBoxStyles>;

export const DefaultCheckbox = withStyles(checkBoxStyles)(
    observer((props: DefaultCheckboxPropsWithStyles) => {
        const { translate } = injectTSDI(I18n);
        const {
            field,
            checked,
            label,
            labelArgs = [],
            disabled,
            grid,
            onClick,
            'data-role': dataRole,
            classes,
            ...checkboxProps
        } = props;

        const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
            if (field) {
                field.value = !field.value;
            }
            if (onClick) {
                onClick(e);
            }
        };

        const error = field?.error;

        const checkbox = (
            <FormControl error={Boolean(error)}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={error && classes.error}
                            checked={checked ?? field?.value}
                            disabled={disabled}
                            onClick={handleClick}
                            {...checkboxProps}
                        />
                    }
                    label={translate(label, ...labelArgs)}
                />
                {error && (
                    <FormHelperText error={true} className={classes.helperText}>
                        {transform(translate(error), 'sentence')}
                    </FormHelperText>
                )}
            </FormControl>
        );

        if (grid) {
            return (
                <Grid item {...grid} data-role={dataRole}>
                    {checkbox}
                </Grid>
            );
        }

        return <div data-role={dataRole}>{checkbox}</div>;
    })
);
