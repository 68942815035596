import { IReactionDisposer, reaction } from 'mobx';
import { component, destroy, initialize, inject } from 'tsdi';
import { wrapRequest } from 'wrap-request';

import { Api } from './api';
import { StudioControllerV2 } from './api/client';
import { featureEnabled } from './common/feature';
import { TokenStore } from './token-store';

export type FeatureModule = Awaited<
    ReturnType<StudioControllerV2['getEnabledCommunicationFeatureModules']>
>[0];

@component
export class FeatureModuleStore {
    @inject
    private tokenStore!: TokenStore;
    @inject
    private api!: Api;

    private disposers: IReactionDisposer[] = [];

    public cummunicationFeatureModules = wrapRequest((studio: number) =>
        this.api.client.StudioControllerV2.getEnabledCommunicationFeatureModules(
            studio
        )
    );

    public hasCommunicationFeatureModule = (module?: FeatureModule) => {
        if (!module || !featureEnabled('COMMUNICATION_FEATURE_MODULES')) {
            return true;
        }

        const { loading, result } = this.cummunicationFeatureModules;

        if (loading || !result) {
            return false;
        }

        return result.some(m => m === module);
    };

    @initialize
    protected init() {
        this.disposers.push(
            reaction(
                () => this.tokenStore.studioId,
                studioId => {
                    if (
                        !studioId ||
                        !featureEnabled('COMMUNICATION_FEATURE_MODULES')
                    ) {
                        return;
                    }

                    return this.cummunicationFeatureModules.request(studioId);
                },
                { fireImmediately: true }
            )
        );
    }

    @destroy
    protected destroy() {
        this.disposers.forEach(dispose => dispose());
    }
}
