import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { LocalDate } from '@spa-frontend/date-lib';

import { CancelContractManualRequestDto } from '../../core/api/dtos';
import { WrappedField } from '../../core/common/forms';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { ContractTerminationStore } from '../contract-termination-store';

interface ManualCancellationDatePickerProps {
    cancellationDateField: WrappedField<CancelContractManualRequestDto['cancellationDate']>;
    cancellationDateType: CancelContractManualRequestDto['cancellationDateType'];
}

export const ManualCancellationDatePicker = observer((props: ManualCancellationDatePickerProps) => {
    const { translate } = injectTSDI(I18n);
    const { cancellationDateField, cancellationDateType } = props;
    const { updateDateIfValid } = injectTSDI(ContractTerminationStore);

    return (
        <TextField
            fullWidth
            type="date"
            data-role="manual-contract-termination-date"
            inputProps={{ min: LocalDate.now().addDays(1) }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            label={translate('public.content.terminate.contract.absolute.cancellation.date')}
            value={cancellationDateField.value?.toString() || ''}
            onChange={event => updateDateIfValid(event, cancellationDateField)}
            error={cancellationDateType === 'ABSOLUTE_CANCELLATION_DATE' &&
                Boolean(cancellationDateField.error)
            }
            helperText={cancellationDateType === 'ABSOLUTE_CANCELLATION_DATE'
                ? translate(cancellationDateField.error)
                : undefined
            }
            required={cancellationDateType === 'ABSOLUTE_CANCELLATION_DATE'}
        />
    );
});
