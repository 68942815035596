import styled, { css } from 'styled-components';

export const MEDIUM = 768;

export const Colors = {
    blue: '#00ade2',
    creamy: '#fbfbfb',
    white: '#ffffff',
    green: '#5ebd84',
    disabledText: '#c3c2c2',
    disabledBackground: '#e9e9e9'
};

export const defaultFont = () => css`
    font-family: Raleway, sans-serif;
`;

export const defaultText = () => css`
    ${defaultFont()};
    color: #000;
    font-size: 16px;
    margin: 5px 0;
`;

export interface HeadingProps {
    presentational?: boolean;
}

export const Heading = styled.h1<HeadingProps>`
    ${defaultText()};
    font-size: 26px;
    font-weight: bold;
    margin: 20px 0 0;

    align-self: flex-start;

    @media (min-width: ${MEDIUM}px) {
        align-self: center;
    }

    ${props =>
        props.presentational &&
        css`
            max-width: 400px;
            text-align: center;
            line-height: 150%;
            align-self: center;
        `}
`;

export const CenterWrapper = styled.div`
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
