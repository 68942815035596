import { observable, reaction, when } from 'mobx';
import { MatchResultParams, Router as TakemeRouter } from 'takeme';
import { component, initialize, inject, TSDI } from 'tsdi';

import { I18n } from './i18n';
import { UrlStore } from './url-store';

export type LinkFn = (id?: LinkProps) => string;
export type LinkProps = string | number;

export interface Links {
    [key: string]: LinkFn;

    emailVerification(): string;

    emailRejection(): string;

    communicationSettings(): string;

    additionalAgreement(): string;

    contractTermination(): string;

    bookingConfirmation(): string;

    bookingCancelation(): string;

    promotionTest(): string;

    initial(): string;
}

export const links: Links = {
    emailVerification: () => 'emailVerification*',
    emailRejection: () => 'emailRejection*',
    communicationSettings: () => 'communicationSettings',
    additionalAgreement: () => 'additionalAgreement',
    contractTermination: () => 'contractTermination*',
    bookingConfirmation: () => 'bookingConfirmation',
    bookingCancelation: () => 'bookingCancelation',
    promotionTest: () => 'promotionTest*',
    initial: () => '__INITIAL__'
};

@component
export class Router {
    @inject
    private tsdi!: TSDI;
    @inject
    private urlStore!: UrlStore;
    @inject
    private i18n!: I18n;

    @observable
    public path = '';
    @observable
    public route = links.initial();
    @observable
    public params: MatchResultParams = { splat: '' };

    private router = new TakemeRouter([
        {
            $: '/',
            beforeEnter: () => {
                return {
                    redirect: `/${this.routeByTask()}`
                };
            }
        },
        {
            $: `/${links.emailVerification()}`,
            enter: ({ newPath, params }) => {
                document.title = this.i18n.translate(
                    'public.content.email.verification.verified.title'
                );

                this.route = links.emailVerification();
                this.path = newPath;
                this.params = params;
            }
        },
        {
            $: `/${links.emailRejection()}`,
            enter: ({ newPath, params }) => {
                document.title = this.i18n.translate(
                    'public.content.email.verification.rejected.title'
                );

                this.route = links.emailRejection();
                this.path = newPath;
                this.params = params;
            }
        },
        {
            $: `/${links.communicationSettings()}`,
            enter: ({ newPath, params }) => {
                document.title = this.i18n.translate(
                    'public.content.communication.header'
                );

                this.route = links.communicationSettings();
                this.path = newPath;
                this.params = params;
            }
        },
        {
            $: `/${links.additionalAgreement()}`,
            enter: ({ newPath, params }) => {
                document.title = this.i18n.translate(
                    'public.content.additional.agreement'
                );

                this.route = links.additionalAgreement();
                this.path = newPath;
                this.params = params;
            }
        },
        {
            $: `/${links.contractTermination()}`,
            enter: ({ newPath, params }) => {
                document.title = this.i18n.translate(
                    'public.content.terminate.contract'
                );

                this.route = links.contractTermination();
                this.path = newPath;
                this.params = params;
            }
        },
        {
            $: `/${links.bookingConfirmation()}`,
            enter: ({ newPath, params }) => {
                document.title = this.i18n.translate(
                    'public.booking.confirmation'
                );

                this.route = links.bookingConfirmation();
                this.path = newPath;
                this.params = params;
            }
        },
        {
            $: `/${links.bookingCancelation()}`,
            enter: ({ newPath, params }) => {
                document.title = this.i18n.translate(
                    'public.booking.cancelation'
                );

                this.route = links.bookingCancelation();
                this.path = newPath;
                this.params = params;
            }
        },
        {
            $: `/${links.promotionTest()}`,
            enter: ({ newPath, params }) => {
                document.title = 'Promotion landing page test';
                this.route = links.promotionTest();
                this.path = newPath;
                this.params = params;
            }
        }
    ]);

    @initialize
    public async init() {
        await when(() => this.i18n.translationsReady);

        this.setInitialValues();
        this.router.init();

        reaction(
            () => this.route,
            route => this.manageScopes(route),
            {
                fireImmediately: true
            }
        );
    }

    private routeByTask(): string {
        if (this.urlStore.hasParams('email', 'studio')) {
            return links.emailVerification();
        }
        if (this.urlStore.hasParams('tenant', 'encryptedCustomerId', 'hash')) {
            return links.communicationSettings();
        }
        return '';
    }

    private setInitialValues(): void {
        this.route = links.initial();
        this.path = '';
        this.params = { splat: '' };
    }

    public enterScope(scope: string): void {
        this.tsdi.getScope(scope).enter();
    }

    public leaveScope(scope: string, checkRoute = false): void {
        if (checkRoute && this.route.startsWith(scope)) {
            return;
        }

        this.tsdi.getScope(scope).leave();
    }

    private manageScopes(route?: string): void {
        if (!route) {
            return;
        }

        Object.keys(links).forEach(key => {
            const linkFn = links[key];
            const link = linkFn();

            if (route.startsWith(link)) {
                this.enterScope(link);
            } else {
                this.leaveScope(link);
            }
        });
    }
}
