import { bind } from 'decko';
import { action, computed, observable } from 'mobx';
import { component, initialize } from 'tsdi';

import { getUrlParams } from './common/utils';
import { Locale } from './i18n';

export type SearchParam =
    | 'locale'
    | 'tenant'
    | 'email'
    | 'studio'
    | 'encryptedCustomerId'
    | 'hash';

@component
export class UrlStore {
    @observable
    private url = window.location.href;

    @observable
    public locale?: string | null;
    @observable
    public tenant?: string | null;

    @observable
    public email?: string | null;
    @observable
    public studio?: string | null;
    @observable
    public bookingId?: string;

    @observable
    public encryptedCustomerId?: string | null;
    @observable
    public hash?: string | null;

    @observable
    public customerUuid?: string | null;

    @initialize
    public init(): void {
        this.readUrlParams();
    }

    @computed
    private get params(): URLSearchParams {
        return getUrlParams(this.url);
    }

    @action
    private readUrlParams(): void {
        const { params } = this;

        this.locale = params.get('locale');
        this.tenant = params.get('tenant');

        this.email = params.get('email');
        this.studio = params.get('studio') || params.get('studioId');
        this.bookingId = params.get('bookingId') ?? undefined;

        this.encryptedCustomerId = params.get('encryptedCustomerId');
        this.hash = params.get('hash');

        this.customerUuid = params.get('uuid');
    }

    @computed
    public get parsedLocale(): Locale | undefined {
        if (!this.locale) {
            return undefined;
        }
        return this.locale.replace('_', '-') as Locale;
    }

    @bind
    public hasParams(...requiredParams: SearchParam[]): boolean {
        // @ts-ignore
        return requiredParams.every(param => this[param]);
    }
}
