import bugsnag, { Bugsnag } from 'bugsnag-js';
import createPlugin from 'bugsnag-react';
import * as React from 'react';
import { component, factory } from 'tsdi';
import { ObjectType } from 'typescript';

import { isDev } from '../core/common/environments';
import { getEnvironment, getTenant } from '../core/endpoint';

export function createReactErrorBoundary(
    client: Bugsnag.Client,
    App: React.ComponentClass
): React.ReactElement<ObjectType> {
    const ErrorBoundary = client.use(createPlugin(React));
    return (
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    );
}

@component
export class BugsnagFactory {
    @factory({ name: 'bugsnag' })
    public bugsnag(): Bugsnag.Client {
        const tenant = getTenant();
        const environment = getEnvironment();

        return bugsnag({
            apiKey: 'cb17119acf7c7cd609442d006c9679bd',
            autoCaptureSessions: true,
            appVersion: 'current',
            releaseStage: environment,
            metaData: {
                tenant
            },
            beforeSend: report => {
                report.context = window.location.hash;
                if (isDev(environment)) {
                    report.ignore();
                }
            }
        });
    }
}
