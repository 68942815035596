import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { StudioPublicDto } from '../../core/api/dtos';

export const StudioInformation = observer(({ studioPublicDto }: { studioPublicDto?: StudioPublicDto }) => (
    <>
        <Grid item xs={12}>
            <Typography
                data-role="contract-termination-studio-information-name">
                {studioPublicDto?.name}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography>
                {`${studioPublicDto?.street} ${studioPublicDto?.houseNumber}`}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography>
                {`${studioPublicDto?.zip} ${studioPublicDto?.city}`}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography>
                {studioPublicDto?.telephone}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography>
                {studioPublicDto?.email}
            </Typography>
        </Grid>
    </>
));
