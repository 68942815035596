import * as React from 'react'; // preact-compat
import styled from 'styled-components';
import { external, inject } from 'tsdi';

import { I18n } from '../core/i18n';
import { MEDIUM } from '../core/styles';
import { CommMatrixStore } from './commmatrix-store';
import { ColumnLong, ColumnShort, LabelColumn } from './styles';

const Wrapper = styled.div`
    display: none;

    @media (min-width: ${MEDIUM}px) {
        display: flex;
    }
`;

@external
export class ChannelsHeaderRow extends React.Component {
    @inject
    private i18n!: I18n;
    @inject
    private commMatrixStore!: CommMatrixStore;

    public render(): JSX.Element {
        const { translate } = this.i18n;
        const { channelsWithAll } = this.commMatrixStore;

        return (
            <Wrapper>
                <ColumnLong />
                {channelsWithAll.map(channel => (
                    <ColumnShort key={channel.label}>
                        <LabelColumn key={channel.label}>
                            {translate(channel.label)}
                        </LabelColumn>
                    </ColumnShort>
                ))}
            </Wrapper>
        );
    }
}
