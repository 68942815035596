import {
    action,
    computed,
    IReactionDisposer,
    observable,
    reaction
} from 'mobx';
import { get } from 'ts-get';
import { component, destroy, initialize, inject } from 'tsdi';

import { Api } from '../core/api';
import {
    CommunicationChannelDto,
    CustomerCommunicationConfigurationDto
} from '../core/api/dtos';
import { featureEnabled } from '../core/common/feature';
import { FeatureModuleStore } from '../core/feature-module-store';
import { TokenStore } from '../core/token-store';
import { MessageStore } from '../message/message-store';
import { enabledChannelsStringified } from './channel-store';

export interface Topic {
    label: string;
    text: string;
    databaseId?: number;
    showAsterisk: boolean;
    activeChannels: (CommunicationChannelEnum | 'ALL')[];
    disabledChannels: (CommunicationChannelEnum | 'ALL')[];
}

export type CommunicationChannelEnum = CommunicationChannelDto['communicationChannel'];

export interface Channel {
    key: CommunicationChannelEnum | 'ALL';
    label: string;
}

function getTranslationKeyForChannel(
    channel: CommunicationChannelEnum
): I18nKey {
    const mySportsLabel = featureEnabled('COMM_MATRIX_MYSPORTS_RENAME')
        ? 'comm.center.data.privacy.app'
        : 'comm.center.data.privacy.mysports';
    switch (channel) {
        case 'EMAIL':
            return 'comm.center.data.privacy.email';
        case 'LETTER':
            return 'comm.center.data.privacy.letter';
        case 'MYSPORTS_MESSAGE':
            return mySportsLabel;
        case 'TEXT_MESSAGE':
            return 'comm.center.data.privacy.textmessage';
        case 'PHONE':
            return 'comm.center.data.privacy.phone';
        default:
            return '';
    }
}

function getPriorityForChannel(
    channelToFindIndexFor: CommunicationChannelEnum | 'ALL'
): number {
    if (!channelToFindIndexFor) {
        return -1;
    }
    return [
        'ALL',
        'MYSPORTS_MESSAGE',
        'EMAIL',
        'TEXT_MESSAGE',
        'PHONE',
        'LETTER'
    ].indexOf(channelToFindIndexFor);
}

function byChannelOrder(channel1: Channel, channel2: Channel): number {
    return (
        getPriorityForChannel(channel1.key) -
        getPriorityForChannel(channel2.key)
    );
}

export const CHANNEL_ALL: Channel = {
    key: 'ALL',
    label: 'commons.all'
};

@component
export class CommMatrixStore {
    @inject
    private api!: Api;
    @inject
    private tokenStore!: TokenStore;
    @inject
    private messageStore!: MessageStore;
    @inject
    private featureModuleStore!: FeatureModuleStore;

    @observable
    public updating = false;
    @observable
    public updatingSuccess = false;
    @observable
    public commMatrix?: CustomerCommunicationConfigurationDto[];
    @observable
    public commMatrixInitial?: CustomerCommunicationConfigurationDto[];

    private disposers: IReactionDisposer[] = [];

    @computed
    public get topics(): Topic[] {
        const topicsList = this.commMatrix || [];
        return topicsList.map(topic => {
            const { databaseId, channels } = topic;

            const getChannelName = (channel: CommunicationChannelDto) =>
                channel.communicationChannel as CommunicationChannelEnum;

            const availableChannels =
                channels?.filter(({ communicationChannel }) =>
                    this.isChannelFeatureModuleEnabled(communicationChannel)
                ) || [];
            const activeChannels = availableChannels
                .filter(channel => channel.active)
                .map(getChannelName);
            const disabledChannels = availableChannels
                .filter(channel => !channel.customerOverridable)
                .map(getChannelName);

            return {
                databaseId,
                activeChannels,
                disabledChannels,
                label: get(topic, it => it.messageCategoryDto.name, ''),
                text: get(
                    topic,
                    it => it.messageCategoryDto.publicDescription,
                    ''
                ),
                showAsterisk: get(
                    topic,
                    it => it.messageCategoryDto.showAsterisk,
                    false
                )
            };
        });
    }

    @computed
    public get channels(): Channel[] {
        if (!this.commMatrix?.length) {
            return [];
        }
        return (
            this.commMatrix[0].channels
                ?.map(channelDto => channelDto.communicationChannel)
                .filter(channel => this.isChannelFeatureModuleEnabled(channel))
                .map(channel => ({
                    key: channel,
                    label: getTranslationKeyForChannel(channel)
                }))
                .sort(byChannelOrder) ?? []
        );
    }

    @computed
    public get channelsWithAll(): Channel[] {
        if (this.channels.length > 1) {
            return [CHANNEL_ALL, ...this.channels];
        }
        return this.channels;
    }

    @computed
    public get numberOfAllChannels(): number {
        return this.channels.length;
    }

    @initialize
    protected init() {
        this.disposers.push(
            reaction(
                () => this.tokenStore.customerId,
                customerId => customerId && this.loadCommatrix(),
                { fireImmediately: true }
            )
        );
    }

    @destroy
    protected destroy(): void {
        this.disposers.forEach(d => d());
    }

    @action
    private async loadCommatrix(): Promise<void> {
        try {
            const commMatrix = await this.api.client.ConnectApiCommMatrixController.getCommMatrix(
                this.tokenStore.customerId
            );
            this.commMatrix = commMatrix;
            this.commMatrixInitial = commMatrix;
        } catch (e) {
            this.messageStore.displayError(e);
        }
    }

    @action
    public async updateCommatrix(): Promise<void> {
        if (!this.commMatrix) {
            return;
        }

        try {
            this.updating = true;
            const commMatrix = this.commMatrix.map(dto => ({
                ...dto,
                enabledChannels: dto.channels?.filter(channel => channel.active)
            }));
            this.commMatrix = await (() => {
                return this.api.client.ConnectApiCommMatrixController.updateCommMatrixFull(
                    this.tokenStore.customerId,
                    commMatrix
                );
            })();
            this.updatingSuccess = true;
        } catch (e) {
            this.messageStore.displayError(e);
        } finally {
            this.updating = false;
        }
    }

    @computed
    public get commMatrixModified(): boolean {
        return (
            Boolean(this.commMatrixInitial) &&
            enabledChannelsStringified(this.commMatrixInitial) !==
                enabledChannelsStringified(this.commMatrix)
        );
    }

    @computed
    public get hasCategoryAsteriskEnabled(): boolean {
        return (
            this.commMatrixInitial?.some(
                category => category.messageCategoryDto?.showAsterisk
            ) ?? false
        );
    }

    private findTopic(
        topicDatabaseId?: number
    ): CustomerCommunicationConfigurationDto | undefined {
        if (!this.commMatrix) {
            return;
        }

        return this.commMatrix.find(
            topic => topic.databaseId === topicDatabaseId
        );
    }

    @action
    public toggleTopicEnabledChannel(
        topicDatabaseId: number,
        clickedChannelName: CommunicationChannelEnum | 'ALL'
    ): void {
        const topic = this.findTopic(topicDatabaseId);
        if (!topic || !topic.channels || clickedChannelName === 'ALL') {
            return;
        }

        const clickedChannel = topic.channels.find(
            ch => ch.communicationChannel === clickedChannelName
        );

        if (!clickedChannel) {
            return;
        }
        const channels = topic.channels.filter(
            ch => ch.communicationChannel !== clickedChannelName
        );
        channels.push({
            ...clickedChannel,
            active: !clickedChannel.active
        });
        topic.channels = channels;
    }

    @action
    public enableAllChannels(topicDatabaseId: number) {
        this.setAllChannels(topicDatabaseId, true);
    }

    @action
    public disableAllChannels(topicDatabaseId: number) {
        this.setAllChannels(topicDatabaseId, false);
    }

    public setAllChannels(topicDatabaseId: number, active: boolean) {
        const topic = this.findTopic(topicDatabaseId);
        if (!topic || !topic.channels) {
            return;
        }

        topic.channels = topic.channels.map(channel => {
            const { communicationChannel, customerOverridable } = channel;
            if (
                customerOverridable &&
                this.isChannelFeatureModuleEnabled(communicationChannel)
            ) {
                return {
                    ...channel,
                    active
                };
            }
            return channel;
        });
    }

    private isChannelFeatureModuleEnabled(channel: CommunicationChannelEnum) {
        const { hasCommunicationFeatureModule } = this.featureModuleStore;

        switch (channel) {
            case 'EMAIL':
                return hasCommunicationFeatureModule('E_MAIL');
            case 'LETTER':
                return hasCommunicationFeatureModule('LETTER');
            case 'MYSPORTS_MESSAGE':
                return hasCommunicationFeatureModule('MY_SPORTS_MESSAGES');
            case 'TEXT_MESSAGE':
                return hasCommunicationFeatureModule('TEXT_MESSAGE');
            default:
                return;
        }
    }
}
