import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography
} from '@material-ui/core';
import { bind } from 'decko';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { external, inject } from 'tsdi';

import { LoadingPage } from '../components/loading-page';
import { SuccessPage } from '../components/success-page';
import { DefaultCheckbox } from '../components-mui/default-checkbox';
import { I18n } from '../core/i18n';
import { AdditionalAgreementStore } from './additional-agreement-store';

const Wrapper = styled(Grid)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 680px;
    margin: 20px auto;
`;

const TypographyLighter = styled(Typography)`
    && {
        font-weight: 400;
    }
`;

@observer
@external
export class AdditionalAgreement extends React.Component {
    @inject
    private i18n!: I18n;
    @inject
    private additionalAgreementStore!: AdditionalAgreementStore;

    @bind
    private onSubmit(): void {
        this.additionalAgreementStore.onUpdateAgreement();
    }

    public render(): JSX.Element {
        const { translate } = this.i18n;
        const { form, agreementData, loading } = this.additionalAgreementStore;
        const { agreeChecked } = form.wrappedField;

        if (!agreementData || loading) {
            return <LoadingPage />;
        }

        const {
            agreed,
            agreementTitle,
            agreementContent = '',
            customerName,
            customerNumber
        } = agreementData;

        if (agreed === true) {
            return (
                <SuccessPage>
                    {translate('public.content.additional.agreement.success')}
                </SuccessPage>
            );
        }

        const paragraphs = agreementContent.split('\n');

        return (
            <Wrapper>
                <Card>
                    <CardHeader
                        title={agreementTitle}
                        titleTypographyProps={{
                            variant: 'h5'
                        }}
                    />
                    <CardContent>
                        {paragraphs.map((paragraph, index) => (
                            <TypographyLighter
                                paragraph
                                variant="body2"
                                key={index}
                            >
                                {paragraph}
                            </TypographyLighter>
                        ))}
                        <Grid container direction="column" spacing={10}>
                            <DefaultCheckbox
                                field={agreeChecked}
                                label="public.content.additional.agreement.i.agree"
                                labelArgs={[customerName, customerNumber]}
                                color="primary"
                                grid={{ xs: 12 }}
                            />
                            <Grid item xs={12} lg={6}>
                                <Button
                                    variant="contained"
                                    onClick={this.onSubmit}
                                    fullWidth
                                    type="submit"
                                    color="primary"
                                >
                                    {translate('commons.submit')}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Wrapper>
        );
    }
}
