import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { LocalDate } from '@spa-frontend/date-lib';

import { GetActiveContractsResponseDto } from '../../core/api/dtos';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { ContractTerminationStore } from '../contract-termination-store';

export interface CancellationDatePickerProps {
    contract: GetActiveContractsResponseDto;
    minDate: 'TODAY' | 'EARLIEST_FOR_CONTRACT';
}

export const CancellationDatePicker = observer(
    (props: CancellationDatePickerProps) => {
        const { translate } = injectTSDI(I18n);
        const contractTerminationStore = injectTSDI(ContractTerminationStore);
        const cancelContractForm = contractTerminationStore.getCancelContractForm(
            props.contract.contractId
        );

        if (!cancelContractForm || !props.contract.cancellationDates) {
            return <></>;
        }

        const { cancellationDate } = cancelContractForm.wrappedField;
        const minDate = props.minDate === 'TODAY'
            ? LocalDate.now()
            : props.contract.cancellationDates[0];

        return (
            <TextField
                fullWidth
                required
                type="date"
                inputProps={{
                    min: minDate
                }}
                InputLabelProps={{
                    shrink: true
                }}
                variant="outlined"
                label={translate('public.content.terminate.contract.by')}
                value={cancellationDate.value?.toString()}
                onChange={event =>
                    contractTerminationStore.updateDateIfValid(
                        event,
                        cancellationDate
                    )
                }
                data-role="contract-termination-date"
            />
        );
    }
);
