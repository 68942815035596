import {
    FieldState as FieldStateLib,
    FormState as FormStateLib,
    Validator
} from 'formstate';
import debounce from 'lodash.debounce';
import { computed } from 'mobx';

type NotUndefined<T> = Exclude<T, undefined>;

// inspired by Required of Typescript
export type NotUndefinedFields<T> = { [P in keyof T]-?: NotUndefined<T[P]> };

export interface FieldApi<T> {
    value: T;
    error: string | undefined;
    reset(value: T): void;
}

export interface WrappedField<T> extends FieldApi<T> {
    fromInput: string;
    hasError: boolean;
    validating: boolean;
    dirty: boolean;
    autoValidationDefault?: boolean;
}

export interface Hook<T> {
    afterSet?(oldValue: T, newValue: T): void;
}

export interface AnyHook {
    afterSet(): void;
}

export function fieldWrapper<T>(
    field: FieldStateLib<T>,
    setTransform: (value: T) => T = (value: T) => value,
    hooks?: Hook<T>
): WrappedField<T> {

    return {
        get value(): T {
            return field.value;
        },

        set value(value: T) {
            const oldValue = field.value;
            const newValue = setTransform(value);
            field.onChange(newValue);
            if (hooks && hooks.afterSet) {
                hooks.afterSet(newValue, oldValue);
            }
        },

        /** use when setting values from input-fields */
        set fromInput(value: string) {
            const oldValue = field.value;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newValue = setTransform(value as any);
            field.onChange(newValue);
            if (hooks && hooks.afterSet) {
                hooks.afterSet(newValue, oldValue);
            }
        },

        reset(value: T): void {
            const oldValue = field.value;
            const newValue = setTransform(value);

            field.reset(newValue);

            if (hooks && hooks.afterSet) {
                hooks.afterSet(newValue, oldValue);
            }
        },

        set error(error: string | undefined) {
            field.error = error;
        },

        get error(): string | undefined {
            return field.error;
        },

        get validating(): boolean {
            return field.validating;
        },

        set validating(status: boolean) {
            field.validating = status;
        },

        get hasError(): boolean {
            return field.hasError;
        },

        get dirty(): boolean {
            return field.dirty || false;
        }
    };
}

export type FieldStateHolder<M> = { [K in keyof M]: FieldStateLib<M[K]> };

export type WrappedFieldHolder<M> = NotUndefinedFields<
    { [K in keyof M]: WrappedField<M[K]> }
>;

function makeWrappedField<
    M,
    F extends FieldStateHolder<M>,
    W extends WrappedFieldHolder<M>
>(fieldStates: F, hooks?: Hook<M>): W {
    return Object.entries(fieldStates).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (res, [key, value]: [string, any]) => {
            res[key] = fieldWrapper(value, undefined, hooks);
            return res;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {} as any
    ) as W;
}

function makeModel<M, F extends FieldStateHolder<M>>(fieldStates: F): M {
    return Object.entries(fieldStates).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (res, [key, value]: [string, any]) => {
            res[key] = value.value;
            return res;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {} as any
    ) as M;
}

function getHook<M>(hooks?: AnyHook): Hook<M> | undefined {
    if (!hooks) {
        return undefined;
    }
    const forward = debounce(() => hooks.afterSet(), 50);
    return {
        afterSet: (newValue: unknown, oldValue: unknown) => {
            if (newValue !== oldValue) {
                forward();
            }
        }
    };
}

export class Form<M> {
    private readonly fieldStates: FieldStateHolder<M>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly formState: FormStateLib<any>;

    public readonly wrappedField: WrappedFieldHolder<M>;

    constructor(fieldStates: FieldStateHolder<M>, hooks?: AnyHook) {
        this.fieldStates = fieldStates;
        this.formState = new FormStateLib(fieldStates);
        this.wrappedField = makeWrappedField(fieldStates, getHook(hooks));
    }

    public reset(): void {
        this.formState.reset();
    }

    public validators(...validators: Validator<WrappedFieldHolder<M>>[]): this {
        const validatorsAgainstWrappedField = validators.map(validator => () =>
            validator(this.wrappedField)
        );

        this.formState.validators(...validatorsAgainstWrappedField);
        return this;
    }

    public async validate(): Promise<boolean> {
        return !(await this.formState.validate()).hasError;
    }

    public async getValidModel(): Promise<M | undefined> {
        const { formState } = this;

        const val = await formState.validate();
        if (val.hasError) {
            return undefined;
        }

        return makeModel(this.fieldStates);
    }

    public setModel(m: M): Form<M> {
        Object.entries(this.wrappedField).forEach(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ([key, field]: [string, any]) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                field.value = (m as any)[key];
            }
        );
        return this;
    }

    @computed
    public get dirty(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Object.values<WrappedField<any>>(this.wrappedField).some(
            field => field.dirty
        );
    }
}

export class Field<T> extends FieldStateLib<T> {
    constructor(
        initValue: T,
        validators?: Validator<T> | Validator<T>[],
        wait = 200,
        autoValidation = true
    ) {
        super(initValue);
        this.setAutoValidationDefault(autoValidation);
        this.setAutoValidationDebouncedMs(wait);

        if (validators) {
            if (Array.isArray(validators)) {
                this.validators(...validators);
            } else {
                this.validators(validators);
            }
        }
    }
}
