import { computed, observable } from 'mobx';
import React, { RefObject } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { component, initialize, inject } from 'tsdi';
import { wrapRequest } from 'wrap-request';

import { LocalDate, toLocalDate } from '@spa-frontend/date-lib';

import { Api } from '../core/api';
import {
    CancelContractManualRequestDto,
    CancelContractRequestDto,
    ConnectApiBasicStudioDataDtoV2,
    GetActiveContractsRequestDto,
    GetActiveContractsResponseDto
} from '../core/api/dtos';
import { featureEnabled } from '../core/common/feature';
import { Field, Form, WrappedField } from '../core/common/forms';
import { injectTSDI } from '../core/common/tsdi';
import { required, requiredIf } from '../core/common/validation';
import { I18n } from '../core/i18n';
import { UrlStore } from '../core/url-store';

@component
export class ContractTerminationStore {
    @inject
    private api!: Api;

    @inject
    private urlStore!: UrlStore;

    @observable
    private _contractToTerminateId?: number;

    @observable
    private _hasContracts = false;

    @observable
    public studios?: ConnectApiBasicStudioDataDtoV2[];

    public readonly showContractsRequest = wrapRequest(
        ({ recaptchaToken, memberData }) =>
            this.api.client.ConnectApiContractController.getActiveContracts(
                { recaptchaToken },
                memberData
            )
    );

    public readonly cancelContractRequest = wrapRequest(
        ({ recaptchaToken, cancelContractDto }) =>
            this.api.client.ConnectApiContractController.cancelContract(
                { recaptchaToken },
                cancelContractDto
            )
    );

    public readonly getStudiosRequest = wrapRequest(() =>
        this.api.client.ConnectApiContractControllerV2.getActiveStudiosInGermanyV2()
    );

    public getStudioInfo = wrapRequest(async (studioId: number) =>
        this.api.client.ConnectApiContractController.getStudioInfo(studioId)
    );

    public getLegalLinks = wrapRequest((studioId: number) =>
        this.api.client.StudioControllerV2.getStudioLegalLinks(studioId)
    );

    public readonly cancelContractManualRequest = wrapRequest(
        ({ recaptchaToken, cancelContractManualDto }) =>
            this.api.client.ConnectApiContractController.cancelContractManual(
                { recaptchaToken },
                cancelContractManualDto
            )
    );

    public readonly memberDataForm = new Form<GetActiveContractsRequestDto>({
        firstname: new Field<string>('', required()),
        lastname: new Field<string>('', required()),
        dateOfBirth: new Field<LocalDate>(
            LocalDate.now().subDays(1),
            required()
        ),
        customerNumber: new Field<string | undefined>(undefined)
    });

    private cancelContractForms: Form<CancelContractRequestDto>[] = [];

    private readonly cancellationDateTypeField = new Field<
        CancelContractManualRequestDto['cancellationDateType']
    >('NEXT_POSSIBLE_CANCELLATION_DATE', required());

    private readonly cancellationTypeField = new Field<
        CancelContractManualRequestDto['cancellationType']
    >('ORDINARY_CANCELLATION', required());

    public readonly cancelContractManualForm: Form<
        CancelContractManualRequestDto
    > = new Form<CancelContractManualRequestDto>({
        firstname: new Field<string>('', required()),
        lastname: new Field<string>('', required()),
        dateOfBirth: new Field<LocalDate>(LocalDate.now(), required()),
        customerNumber: new Field<string | undefined>(undefined),
        studioId: new Field<number | undefined>(undefined, required()),
        additionalInformation: new Field<string | undefined>(undefined),
        confirmationEmail: new Field<string | undefined>(undefined),
        cancelationReasonId: new Field<number | undefined>(
            undefined,
            requiredIf(
                () =>
                    this.cancellationTypeField.value ===
                    'EXTRAORDINARY_CANCELLATION'
            )
        ),
        cancellationDateType: this.cancellationDateTypeField,
        cancellationDate: new Field<LocalDate | undefined>(
            undefined,
            requiredIf(
                () =>
                    this.cancellationDateTypeField.value ===
                    'ABSOLUTE_CANCELLATION_DATE'
            )
        ),
        cancellationType: this.cancellationTypeField
    });

    @initialize
    protected init = async () => {
        await this.getStudiosRequest.request();
        this.studios = this.getStudiosRequest.result?.sort(
            (studioA, studioB) => {
                if (
                    !studioA.studioPublicDto?.name ||
                    !studioB.studioPublicDto?.name
                ) {
                    return 0;
                }
                return studioA.studioPublicDto.name.localeCompare(
                    studioB.studioPublicDto.name
                );
            }
        );

        await this.prefillSelectedStudio();
    };

    private prefillSelectedStudio = async () => {
        const selectedStudioId =
            (this.urlStore.studio && parseInt(this.urlStore.studio, 10)) ||
            undefined;
        if (
            selectedStudioId &&
            this.studios?.some(studio => studio.id === selectedStudioId)
        ) {
            this.cancelContractManualForm.wrappedField.studioId.reset(
                selectedStudioId
            );
            await this.getStudioInfo.request(selectedStudioId);
        } else {
            if (this.getStudiosRequest.result?.length && this.studios?.length) {
                this.cancelContractManualForm.wrappedField.studioId.reset(
                    this.studios[0].id
                );
                await this.getStudioInfo.request(this.studios[0].id);
            }
        }
    };

    @computed
    public get hasContracts(): boolean {
        return this._hasContracts;
    }

    @computed
    public get contractToTerminateId(): number | undefined {
        return this._contractToTerminateId;
    }

    public get extraordinaryCancellationHelperText(): string {
        const { translate } = injectTSDI(I18n);
        return featureEnabled(
            'EXTRAORDINARY_CANCELLATION_ALTERNATIVE_HELPER_TEXT'
        )
            ? translate(
                  'public.content.terminate.contract.type.helper.alternative'
              )
            : translate('public.content.terminate.contract.type.helper');
    }

    public get manualExtraordinaryCancellationHelperText(): string {
        const { translate } = injectTSDI(I18n);
        return featureEnabled(
            'EXTRAORDINARY_CANCELLATION_ALTERNATIVE_HELPER_TEXT'
        )
            ? translate(
                  'public.content.terminate.contract.type.helper.manual.alternative'
              )
            : translate('public.content.terminate.contract.type.helper.manual');
    }

    public getStudio = (
        studioId: number | undefined
    ): ConnectApiBasicStudioDataDtoV2 | undefined => {
        return this.studios?.find(studio => studio.id === studioId);
    };

    public getCancelContractForm = (contractId: number | undefined) =>
        this.cancelContractForms.find(
            form => form.wrappedField.contractId.value === contractId
        );

    public addCancelContractForm = (
        contract: GetActiveContractsResponseDto
    ) => {
        const customerNumber = this.memberDataForm.wrappedField.customerNumber
            .value;
        const firstPossibleCancellationDate = contract.cancellationDates
            ? contract.cancellationDates[0]
            : undefined;
        if (
            contract.contractId &&
            customerNumber &&
            firstPossibleCancellationDate
        ) {
            const cancellationTypeField = new Field<
                CancelContractRequestDto['cancellationType']
            >('ORDINARY_CANCELLATION', required());
            this.cancelContractForms.push(
                new Form<CancelContractRequestDto>({
                    firstname: new Field<string>(
                        this.memberDataForm.wrappedField.firstname.value,
                        required()
                    ),
                    lastname: new Field<string>(
                        this.memberDataForm.wrappedField.lastname.value,
                        required()
                    ),
                    dateOfBirth: new Field<LocalDate>(
                        this.memberDataForm.wrappedField.dateOfBirth.value,
                        required()
                    ),
                    customerNumber: new Field<string>(
                        customerNumber,
                        required()
                    ),
                    contractId: new Field<number>(
                        contract.contractId,
                        required()
                    ),
                    cancellationType: cancellationTypeField,
                    cancellationDate: new Field<LocalDate>(
                        firstPossibleCancellationDate,
                        required()
                    ),
                    cancelationReasonId: new Field<number | undefined>(
                        undefined,
                        requiredIf(
                            () =>
                                cancellationTypeField.value ===
                                'EXTRAORDINARY_CANCELLATION'
                        )
                    ),
                    confirmationEmail: new Field<string | undefined>(''),
                    additionalInformation: new Field<string | undefined>('')
                })
            );
        }
    };

    public handleMemberDataSubmit = async (
        recaptchaRef: React.RefObject<ReCAPTCHA>
    ) => {
        const memberData = await this.memberDataForm.getValidModel();
        if (!memberData) {
            return;
        }
        const recaptchaToken = await recaptchaRef.current?.executeAsync();
        if (!recaptchaToken) {
            recaptchaRef.current?.reset();
            return;
        }
        await this.showContractsRequest.request({
            recaptchaToken,
            memberData
        });

        const studioId =
            this.showContractsRequest.result?.length &&
            this.showContractsRequest.result[0].studioId;

        if (studioId && this.studios?.some(studio => studio.id === studioId)) {
            await this.getStudioInfo.request(studioId);
        }

        this._hasContracts = this.showContractsRequest.state === 'fetched';
        recaptchaRef.current?.reset();
    };

    public handleTerminate = async (
        recaptchaRef: RefObject<ReCAPTCHA>,
        contractId: number
    ) => {
        this._contractToTerminateId = contractId;
        const memberFieldsValid = await this.memberDataForm.validate();
        const cancelContractDto = await this.getCancelContractForm(
            contractId
        )?.getValidModel();
        if (!memberFieldsValid || !cancelContractDto) {
            return;
        }
        const recaptchaToken = await recaptchaRef.current?.executeAsync();
        if (!recaptchaToken) {
            recaptchaRef.current?.reset();
            return;
        }
        await this.cancelContractRequest.request({
            recaptchaToken,
            cancelContractDto
        });
        recaptchaRef.current?.reset();
    };

    public handleTerminateManual = async (
        recaptchaRef: RefObject<ReCAPTCHA>
    ) => {
        const memberFieldsValid = await this.memberDataForm.validate();
        this.copyMemberDataFields();
        const cancelContractManualDto = await this.cancelContractManualForm?.getValidModel();
        if (!memberFieldsValid || !cancelContractManualDto) {
            return;
        }
        const recaptchaToken = await recaptchaRef.current?.executeAsync();
        if (!recaptchaToken) {
            return;
        }
        await this.cancelContractManualRequest.request({
            recaptchaToken,
            cancelContractManualDto
        });
        recaptchaRef.current?.reset();
    };

    private copyMemberDataFields = () => {
        this.cancelContractManualForm.wrappedField.firstname.reset(
            this.memberDataForm.wrappedField.firstname.value
        );
        this.cancelContractManualForm.wrappedField.lastname.reset(
            this.memberDataForm.wrappedField.lastname.value
        );
        this.cancelContractManualForm.wrappedField.dateOfBirth.reset(
            this.memberDataForm.wrappedField.dateOfBirth.value
        );
        this.cancelContractManualForm.wrappedField.customerNumber.reset(
            this.memberDataForm.wrappedField.customerNumber.value
        );
    };

    public updateDateIfValid = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        dateField: WrappedField<LocalDate | undefined>
    ) => {
        const localDate = toLocalDate(event.target.value);
        if (
            dateField &&
            localDate &&
            localDate.isValid() &&
            !localDate.isSame(LocalDate.now())
        ) {
            dateField.reset(localDate);
        }
    };

    public updateCancellationType = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        cancellationType: WrappedField<
            CancelContractManualRequestDto['cancellationType']
        >,
        cancellationDateType?: WrappedField<
            CancelContractManualRequestDto['cancellationDateType']
        >
    ) => {
        const newCancellationType = event.target
            .value as CancelContractManualRequestDto['cancellationType'];
        cancellationType.reset(newCancellationType);
        if (newCancellationType === 'EXTRAORDINARY_CANCELLATION') {
            cancellationDateType?.reset('ABSOLUTE_CANCELLATION_DATE');
        }
    };
}
