import { component, initialize, inject } from 'tsdi';
import { UrlStore } from './url-store';
import { wrapRequest } from 'wrap-request';
import { Api } from './api';
import { reaction } from 'mobx';
import { CentralLegalLinkDto } from './api/dtos';

const magicLineLegalLinks: CentralLegalLinkDto[] = [
    {
        centralLegalLinkDocumentType: 'IMPRINT',
        legalLinkType: 'EXTERNAL_URL',
        content: 'https://www.magicline.com/de/impressum/'
    },
    {
        centralLegalLinkDocumentType: 'PRIVACY',
        content: 'https://public.sportalliance.com/magicline/de/privacy',
        legalLinkType: 'EXTERNAL_URL'
    }
]

@component
export class StudioStore {
    @inject
    private urlStore!: UrlStore;
    @inject
    private api!: Api;

    public legalLinks = wrapRequest(async (studio: number) => {
            const { legalLinks } = await this.api.client.StudioControllerV2.getStudioLegalLinks(studio);
            if (!legalLinks || legalLinks.length === 0) {
                return magicLineLegalLinks;
            }
            return legalLinks;
        }, { defaultData: magicLineLegalLinks }
    )

    public studioInfo = wrapRequest((studio: number) => this.api.client.StudioControllerV2.getStudio(studio))

    @initialize
    public async init() {
        reaction(
            () => this.urlStore.studio,
            (studio) => {
                if (!studio) {
                    return;
                }

                const studioId = parseInt(studio, 10);
                this.legalLinks.request(studioId)
                this.studioInfo.request(studioId)
            },
            { fireImmediately: true }
        )
    }
}
