import { ConnectApiErrorResponseDto } from '../api/dtos';

export function getUrlParams(url?: string): URLSearchParams {
    let searchString = url
        ? url.split('?')[1]
        : window.location.href.split('?')[1];
    if (searchString) {
        /*
            If url is valid, with hash part at the end, like so:
            'zzz.com?param=z#/emailVerification
            we need to cut the hash part down
        */
        [searchString] = searchString.split('#');
    }

    return new URLSearchParams(searchString);
}

export function containsErrorWithCode(
    errorCode: ConnectApiErrorResponseDto['errorCodes'][0],
    errors: ConnectApiErrorResponseDto[]
): boolean {
    return errors.some(
        error => error.errorCodes && error.errorCodes.includes(errorCode)
    );
}
