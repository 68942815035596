import { observer } from 'mobx-react';
import * as React from 'react';
import { external, inject } from 'tsdi';

import { Checkbox } from '../components/checkbox';
import { I18n } from '../core/i18n';
import {
    CheckboxField,
    CheckboxFieldInner,
    CheckboxLabel,
    ColumnShort
} from './styles';

export interface ChannelCheckboxProps {
    label: string;
    isChecked: boolean;
    isDisabled?: boolean;
    onClick(): void;
    'data-role'?: string;
}

@external
@observer
export class ChannelCheckbox extends React.Component<ChannelCheckboxProps> {
    @inject
    private i18n!: I18n;

    public render(): JSX.Element {
        const { translate } = this.i18n;
        const {
            label,
            isChecked,
            isDisabled,
            'data-role': dataRole,
            onClick
        } = this.props;

        return (
            <ColumnShort>
                <CheckboxField>
                    <CheckboxFieldInner>
                        <CheckboxLabel>{translate(label)}</CheckboxLabel>
                        <Checkbox
                            checked={isChecked}
                            disabled={isDisabled}
                            onClick={onClick}
                            data-role={dataRole}
                        />
                    </CheckboxFieldInner>
                </CheckboxField>
            </ColumnShort>
        );
    }
}
