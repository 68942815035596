import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

export interface LogoProps {
    src: string;
    height: number;
}
const LogoWrapper = styled.span`
    overflow: hidden;
    display: inline;
    text-align: center;
    //Mimic the layout of <Typography variant="body2" color={'textSecondary'}>
    color: #0000008a;
    font-size: 0.875rem;
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    img {
        height:  ${(props: LogoProps) => `${props.height}px`};
    }
`;

export const Logo = observer((props: LogoProps) => (
        <LogoWrapper {...props}>
            <img  style={{ verticalAlign: 'middle' }} src={props.src}/> <span style={{ verticalAlign: 'middle' }}>Magicline GmbH</span>
        </LogoWrapper>
    ))
