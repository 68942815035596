import { Link } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';

import { featureEnabled } from '../core/common/feature';
import { Text } from './styles';

@observer
export class DataPrivacySubtext extends React.Component {
    public render(): JSX.Element {
        const dataPrivacyDocumentLink =
            'https://www.fitx.de/unternehmen/datenschutz/';
        const contactLink = 'https://www.fitx.de/kontakt';
        return (
            <>
                <Text>
                    (*) Für die markierten Kategorien brauchen wir von dir für
                    die Kommunikation mit dir mittels der App, Anruf, SMS oder
                    E-Mail Deine Einwilligung. Sofern Du hier die entsprechenden
                    Optionen auswählst, willigst Du ein, von FitX Informationen
                    über den jeweils ausgewählten Kanal zu erhalten (dies
                    betrifft dann auch auf dich und deine Mitgliedschaft
                    abgestimmte Inhalte unter ggf. Nutzung von Gesundheits- und
                    Trainingsdaten). Diese Einwilligung kannst Du jederzeit mit
                    Wirkung für die Zukunft widerrufen. Weitere Informationen
                    hierzu findest Du in unserer Datenschutzerklärung unter{' '}
                    <Link
                        href={dataPrivacyDocumentLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {dataPrivacyDocumentLink}
                    </Link>
                </Text>
                {featureEnabled('FITX_ADDITIONAL_PRIVACY_INFORMATION') && (
                    <Text>
                        (**) Diese Funktion steht derzeit nicht zur Verfügung.
                        Es kann hier keine Auswahl zu einer An- oder Abmeldung
                        getroffen werden. Wir arbeiten an einem technischen
                        Update. Meldet euch bei Fragen gerne über unser
                        Kontaktformular hier:{' '}
                        <Link
                            href={contactLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {contactLink}
                        </Link>
                    </Text>
                )}
            </>
        );
    }
}
