import { observer } from 'mobx-react';
import * as React from 'react';

import { CenterWrapper, Colors, Heading } from '../../core/styles';
import { Checkbox } from '../checkbox';

interface SuccessPageProps {
    children: React.ReactNode;
    size?: number;
}

export const SuccessPage = observer((props: SuccessPageProps) => {
    const { children, size = 100 } = props;
    return (
        <CenterWrapper>
            <Checkbox size={size} color={Colors.green} round checked />
            {typeof children === 'string' ? (
                <Heading presentational>{children}</Heading>
            ) : (
                children
            )}
        </CenterWrapper>
    );
});
