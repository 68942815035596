import { computed, observable } from 'mobx';
import { component } from 'tsdi';

import { getClient } from '../api/client';
import { getEndpoint } from '../endpoint';
import { ApiError } from './api-error';

@component
export class Api {
    @observable
    public token: string | undefined;

    @observable
    public authenticationError = false;

    @computed
    public get client() {
        return getClient(getEndpoint(), this.token || '', pretend => {
            pretend.requestInterceptor(request => {
                request.options.headers = new Headers(request.options.headers);
                if (!request.options.headers.has('Content-Type')) {
                    request.options.headers.set(
                        'Content-Type',
                        'application/json'
                    );
                } else if (
                    request.options.headers.get('Content-Type') ===
                    'multipart/form-data'
                ) {
                    // note: strange but true, the fetch API sets the
                    // content-type header on its own
                    request.options.headers.delete('Content-Type');
                }

                // TODO: just a workaround for foo with parameters in paths
                request.url = request.url.replace(/(\w)\/\/(\w)/g, '$1/$2');

                return request;
            });
            pretend.decode(async response => {
                if (response.status === 401) {
                    this.authenticationError = true;
                    throw new Error(response.statusText);
                } else if (response.status >= 400) {
                    throw new ApiError(
                        response.statusText,
                        response.status,
                        await response.json()
                    );
                } else if (response.status >= 500) {
                    throw new ApiError(
                        response.statusText,
                        response.status,
                        await response.json()
                    );
                }
                try {
                    return await response.json();
                } catch (e) {
                    // assume there is no body we can convert to json
                    return undefined;
                }
            });
            return pretend;
        });
    }
}
