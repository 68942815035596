import { Box, Button, Card, CardContent, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { getSitekey } from '../../core/common/recaptcha';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { CancellationAdditionalInfoText } from '../components/cancellation-additional-info-text';
import { CancellationDateTypeSelect } from '../components/cancellation-date-type-select';
import { CancellationReasonSelect } from '../components/cancellation-reason-select';
import { CancellationTypeSelect } from '../components/cancellation-type-select';
import { ConfirmationEmailInput } from '../components/confirmation-email-input';
import { ManualCancellationDatePicker } from '../components/manual-cancellation-date-picker';
import { ContractTerminationStore } from '../contract-termination-store';
import { StyledReCAPTCHA } from '../styles';

export const ContractTerminationManualForm = observer(() => {
    const { translate } = injectTSDI(I18n);
    const contractTerminationStore = injectTSDI(ContractTerminationStore);

    if (!contractTerminationStore.cancelContractManualForm) {
        return <></>;
    }

    const {
        updateCancellationType,
        manualExtraordinaryCancellationHelperText
    } = contractTerminationStore;

    const {
        studioId,
        additionalInformation,
        confirmationEmail,
        cancelationReasonId,
        cancellationDateType,
        cancellationDate,
        cancellationType
    } = contractTerminationStore.cancelContractManualForm.wrappedField;

    const recaptchaRef = React.createRef<ReCAPTCHA>();

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={5}>
                    <Grid item>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={3}
                        >
                            <Grid item xs={12} sm={6}>
                                <CancellationTypeSelect
                                    cancellationTypeField={cancellationType}
                                    onChange={event =>
                                        updateCancellationType(
                                            event,
                                            cancellationType,
                                            cancellationDateType
                                        )
                                    }
                                    helperTextExtraordinaryTermination={
                                        manualExtraordinaryCancellationHelperText
                                    }
                                    data-role="manual-contract-termination-type"
                                />
                            </Grid>
                            <Box width="100%" />
                            {cancellationType.value ===
                                'ORDINARY_CANCELLATION' && (
                                <Grid item xs={12} sm={6}>
                                    <CancellationDateTypeSelect
                                        cancellationDateTypeField={
                                            cancellationDateType
                                        }
                                        cancellationTypeField={cancellationType}
                                    />
                                </Grid>
                            )}
                            {cancellationDateType.value ===
                                'ABSOLUTE_CANCELLATION_DATE' && (
                                <Grid item xs={12} sm={6}>
                                    <ManualCancellationDatePicker
                                        cancellationDateField={cancellationDate}
                                        cancellationDateType={
                                            cancellationDateType.value
                                        }
                                    />
                                </Grid>
                            )}
                            <Box width="100%" />
                            <Grid item xs={12} sm={6}>
                                <CancellationReasonSelect
                                    cancellationTypeField={cancellationType}
                                    cancellationReasonIdField={
                                        cancelationReasonId
                                    }
                                    studioId={studioId.value}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CancellationAdditionalInfoText
                                    additionalInformationField={
                                        additionalInformation
                                    }
                                    helperText={
                                        cancellationType.value ===
                                        'EXTRAORDINARY_CANCELLATION'
                                            ? translate(
                                                  'public.content.terminate.contract.manual.info.extraordinary'
                                              )
                                            : translate(
                                                  'public.content.terminate.contract.manual.info'
                                              )
                                    }
                                    data-role="manual-contract-termination-additional-info"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ConfirmationEmailInput
                                    confirmationEmailField={confirmationEmail}
                                    data-role="manual-confirmation-email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledReCAPTCHA
                                    sitekey={getSitekey()}
                                    ref={recaptchaRef}
                                    size="invisible"
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    data-role="submit-manual-contract-termination"
                                    onClick={() => {
                                        contractTerminationStore.handleTerminateManual(
                                            recaptchaRef
                                        );
                                    }}
                                >
                                    {translate(
                                        'public.content.terminate.contract.now'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
});
