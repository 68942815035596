import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { WrappedField } from '../../core/common/forms';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';

interface CancellationAdditionalInfoTextProps {
    additionalInformationField: WrappedField<string | undefined>;
    helperText: string;
    'data-role'?: string;
}

export const CancellationAdditionalInfoText = observer((props: CancellationAdditionalInfoTextProps) => {
    const { translate } = injectTSDI(I18n);
    const { additionalInformationField, helperText } = props;

    return (
        <TextField
            fullWidth
            label={translate(
                'public.content.terminate.contract.additional.info'
            )}
            variant="outlined"
            multiline
            minRows={5}
            value={additionalInformationField.value || ''}
            inputProps={{
                'data-role': props['data-role']
            }}
            onChange={event =>
                additionalInformationField.reset(
                    event.target.value || undefined
                )
            }
            helperText={helperText}
        />
    );
});
