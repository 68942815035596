export class TenantApplicationBrandType {
    public static readonly MAGICLINE = new TenantApplicationBrandType(
        'Magicline',
        'Magicline GmbH',
        'magicline.com',
        'Magicline',
        'assets/favicon.ico'
    );
    public static readonly PERFECTGYM = new TenantApplicationBrandType(
        'PerfectGym',
        'PerfectGym Solutions S.A.',
        'perfectgym.com',
        'PerfectGym next',
        'assets/favicon-perfectgym.ico'
    );

    public readonly brandName: string;
    public readonly companyName: string;
    public readonly domainName: string;
    public readonly applicationTitle: string;
    public readonly applicationFavIconPath: string;

    constructor(
        brandName: string,
        companyName: string,
        domainName: string,
        applicationTitle: string,
        favIconPath: string
    ) {
        this.brandName = brandName;
        this.companyName = companyName;
        this.domainName = domainName;
        this.applicationTitle = applicationTitle;
        this.applicationFavIconPath = favIconPath;
    }
}
