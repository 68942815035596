import { Icon, IconButton, MenuItem, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { Spinner } from '../../components/spinner';
import { CancelContractManualRequestDto } from '../../core/api/dtos';
import { WrappedField } from '../../core/common/forms';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { ContractTerminationStore } from '../contract-termination-store';

interface CancellationReasonSelectProps {
    cancellationTypeField: WrappedField<
        CancelContractManualRequestDto['cancellationType']
    >;
    cancellationReasonIdField: WrappedField<
        CancelContractManualRequestDto['cancelationReasonId']
    >;
    studioId?: number;
}

export const CancellationReasonSelect = observer(
    (props: CancellationReasonSelectProps) => {
        const { translate } = injectTSDI(I18n);
        const { getStudio, getStudioInfo } = injectTSDI(
            ContractTerminationStore
        );
        const {
            studioId,
            cancellationTypeField,
            cancellationReasonIdField
        } = props;

        const studio = getStudio(studioId);

        if (!studio) {
            return null;
        }

        const deleteCancellationReasonIdButton =
            cancellationReasonIdField.value &&
            cancellationTypeField.value === 'ORDINARY_CANCELLATION' ? (
                <IconButton
                    onClick={() => cancellationReasonIdField.reset(undefined)}
                >
                    <Icon fontSize="inherit">close</Icon>
                </IconButton>
            ) : (
                undefined
            );

        return (
            <TextField
                fullWidth
                select
                required={
                    cancellationTypeField.value === 'EXTRAORDINARY_CANCELLATION'
                }
                data-role="contract-termination-reasons"
                variant="outlined"
                label={translate(
                    'public.content.terminate.contract.cancellation.reason.label'
                )}
                value={cancellationReasonIdField.value || ''}
                onChange={event =>
                    cancellationReasonIdField.reset(
                        event.target.value
                            ? parseInt(event.target.value, 10)
                            : undefined
                    )
                }
                error={Boolean(cancellationReasonIdField.error)}
                helperText={translate(cancellationReasonIdField.error)}
                disabled={!studioId}
                InputProps={{ endAdornment: deleteCancellationReasonIdButton }}
            >
                {getStudioInfo.match({
                    fetched: (data)=> {
                        return  (data?.cancellationReasons || [])?.map(
                            cancelationReason => (
                                <MenuItem
                                    key={cancelationReason.databaseId}
                                    value={cancelationReason.databaseId}
                                    data-role="contract-termination-reason"
                                >
                                    {cancelationReason.name}
                                </MenuItem>
                            )
                        )
                    },
                    loading: ()=> <Spinner />
                })}
            </TextField>
        );
    }
);
