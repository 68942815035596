import { MenuItem, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { GetActiveContractsResponseDto } from '../../core/api/dtos';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';
import { ContractTerminationStore } from '../contract-termination-store';

export interface CancellationDateSelectProps {
    contract: GetActiveContractsResponseDto;
}

export const CancellationDateSelect = observer(
    (props: CancellationDateSelectProps) => {
        const { formatDate, translate } = injectTSDI(I18n);
        const contractTerminationStore = injectTSDI(ContractTerminationStore);
        const cancelContractForm = contractTerminationStore.getCancelContractForm(
            props.contract.contractId
        );
        if (!cancelContractForm) {
            return <></>;
        }
        const { cancellationDate } = cancelContractForm.wrappedField;

        return (
            <TextField
                fullWidth
                required
                select
                variant="outlined"
                label={translate('public.content.terminate.contract.by')}
                value={cancellationDate.value?.toDate().valueOf()}
                onChange={event =>
                    contractTerminationStore.updateDateIfValid(
                        event,
                        cancellationDate
                    )
                }
            >
                {props.contract.cancellationDates?.map(date => (
                    <MenuItem key={date.valueOf()} value={date.valueOf()}>
                        {formatDate(date)}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
);
