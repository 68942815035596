import { observer } from 'mobx-react';
import React from 'react';

import {
    ScrollToTypography
} from '../../components/scroll-to/scroll-to-typography';
import { Spinner } from '../../components/spinner';
import { ApiError } from '../../core/api/api-error';
import { ConnectApiErrorResponseDto } from '../../core/api/dtos';
import { injectTSDI } from '../../core/common/tsdi';
import { containsErrorWithCode } from '../../core/common/utils';
import { I18n } from '../../core/i18n';
import { ContractTerminationStore } from '../contract-termination-store';
import {
    ManualTerminationSuccessPage
} from './manual-termination-success-page';

export const ManualTerminationSuccessPageContainer = observer(() => {
    const { translate } = injectTSDI(I18n);
    const { cancelContractManualRequest } = injectTSDI(
        ContractTerminationStore
    );

    return cancelContractManualRequest.match({
        loading: () => <Spinner />,
        fetched: manualCancellation => (
            <ManualTerminationSuccessPage
                manualCancellation={manualCancellation}
            />
        ),
        error: (e: ApiError) => {
            let errorCode = 'public.content.terminate.contract.error';
            const errors = e.errorMessages as ConnectApiErrorResponseDto[];
            if (errors) {
                if (containsErrorWithCode('SERVER_ERROR', errors)) {
                    errorCode = 'public.content.terminate.contract.error';
                } else if (
                    containsErrorWithCode('OPERATION_NOT_ALLOWED_FOR_STUDIO', errors)
                ) {
                    errorCode = 'public.content.terminate.contract.no.online.offers';
                } else if (containsErrorWithCode('FEATURE_DISABLED', errors)) {
                    errorCode = 'public.content.terminate.disabled';
                } else if (containsErrorWithCode('STUDIO_NOT_IN_GERMANY', errors)) {
                    errorCode = 'public.content.terminate.contract.studio.not.in.germany';
                }
            }
            return (
                <ScrollToTypography color="error" data-value={errorCode}>
                    {translate(errorCode)}
                </ScrollToTypography>
            );
        },
        default: () => <></>
    });
});
