import { observer } from 'mobx-react';
import * as React from 'react'; // preact-compat
import { external, inject } from 'tsdi';

import { LoadingPage } from '../components/loading-page';
import { SuccessPage } from '../components/success-page';
import { featureEnabled } from '../core/common/feature';
import { I18n } from '../core/i18n';
import { Heading } from '../core/styles';
import { ChannelsHeaderRow } from './channels-header-row';
import { CommMatrixStore } from './commmatrix-store';
import { DataPrivacySubtext } from './data-privacy-subtext';
import { MatrixContainer, MatrixGrid } from './styles';
import { SubmitButton } from './submit-button';
import { TopicRow } from './topic-row';

interface CommunicationMatrixProps {
    heading?: string;
}

@observer
@external
export class CommunicationMatrix extends React.Component<
    CommunicationMatrixProps
> {
    @inject
    private commMatrixStore!: CommMatrixStore;
    @inject
    private i18n!: I18n;

    public render(): JSX.Element {
        const { translate } = this.i18n;
        const {
            updating,
            updatingSuccess,
            commMatrix,
            hasCategoryAsteriskEnabled
        } = this.commMatrixStore;
        const { heading } = this.props;
        const showDataPrivacySubtext = featureEnabled('COMM_MATRIX_DATA_PRIVACY') && hasCategoryAsteriskEnabled;

        if (!commMatrix || updating) {
            return <LoadingPage />;
        }

        if (updatingSuccess) {
            return (
                <SuccessPage>
                    {translate(
                        'public.content.communication.settings.save.success'
                    )}
                </SuccessPage>
            );
        }
        return (
            <>
                <MatrixContainer>
                    {heading && <Heading>{heading}</Heading>}
                    <MatrixGrid>
                        <ChannelsHeaderRow />
                        {this.renderTopics()}
                        {/* FIXME: dataPrivacyDocumentLink should only be checked in showDataPrivacySubtext. Typescript only gets that dataPrivacyDocumentLink can't be undefined
                    in later versions. Remove the check when no longer necessary. */}
                        {showDataPrivacySubtext && <DataPrivacySubtext />}
                        <SubmitButton />
                    </MatrixGrid>
                </MatrixContainer>
            </>
        );
    }

    private renderTopics(): JSX.Element[] {
        const { topics } = this.commMatrixStore;

        return topics.map(topic => (
            <TopicRow key={topic.databaseId} topic={topic} />
        ));
    }
}
