import * as dtos from '../core/api/dtos';

export function enabledChannelsStringified(
    commMatrix?: dtos.CustomerCommunicationConfigurationDto[]
): string {
    if (!commMatrix) {
        return '';
    }

    const enabledChannelsSorted = commMatrix.map(topic => {
        const topicChannels = topic.channels || [];
        const channelsNames = topicChannels
            .filter(channel => channel.active)
            .map(channel => channel.communicationChannel)
            .filter(channel => channel);
        return channelsNames.sort();
    });

    return JSON.stringify(enabledChannelsSorted);
}
