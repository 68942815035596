export type Case = 'sentence';

export const transform = (text: string, toCase: Case) => {
    switch (toCase) {
        case 'sentence':
            return sentenceCase(text);
    }
};

export const sentenceCase = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};
