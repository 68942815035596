import { MenuItem, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { CancelContractManualRequestDto } from '../../core/api/dtos';
import { WrappedField } from '../../core/common/forms';
import { injectTSDI } from '../../core/common/tsdi';
import { I18n } from '../../core/i18n';

interface ContractTerminationTypeSelectProps {
    cancellationTypeField: WrappedField<CancelContractManualRequestDto['cancellationType']>;
    helperTextExtraordinaryTermination: string;
    'data-role'?: string;

    onChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}

export const CancellationTypeSelect = observer((props: ContractTerminationTypeSelectProps) => {
    const { translate } = injectTSDI(I18n);
    const {
        cancellationTypeField,
        onChange,
        helperTextExtraordinaryTermination
    } = props;
    return (
        <TextField
            fullWidth
            select
            required
            variant="outlined"
            label={translate(
                'public.content.terminate.contract.type'
            )}
            value={cancellationTypeField.value}
            onChange={onChange}
            error={Boolean(cancellationTypeField.error)}
            helperText={cancellationTypeField.value === 'EXTRAORDINARY_CANCELLATION'
                ? helperTextExtraordinaryTermination
                : undefined}
            data-role={props['data-role']}
        >
            <MenuItem
                key="ORDINARY_CANCELLATION"
                value="ORDINARY_CANCELLATION"
                data-role={`${props['data-role']}-ordinary`}
            >
                {translate(
                    'public.content.terminate.contract.type.ordinary'
                )}
            </MenuItem>
            <MenuItem
                key="EXTRAORDINARY_CANCELLATION"
                value="EXTRAORDINARY_CANCELLATION"
                data-role={`${props['data-role']}-extraordinary`}
            >
                {translate(
                    'public.content.terminate.contract.type.extraordinary'
                )}
            </MenuItem>
        </TextField>
    );
});
