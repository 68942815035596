import { getEnvironment, getTenant } from '../endpoint';
import { isDev, isStage } from './environments';
import { Feature, FeatureFlag, featuresProd, featuresStage } from './feature-flags';

function isEnabledByFeatureFlag(feature: FeatureFlag): boolean {
    const tenant = getTenant();
    if (typeof feature === 'boolean') {
        return feature;
    }
    if (!tenant) {
        return false;
    }
    if ( tenant in feature) {
        // this should work in later typescript versions
        // @ts-ignore
        return feature[tenant];

    }
    return feature['*'];
}

export function featureEnabled(feature: Feature): boolean {
    const environment = getEnvironment();

    if (isDev(environment)) {
        return true;
    } if (isStage(environment)) {
        return isEnabledByFeatureFlag(featuresStage[feature]);
    }
    return isEnabledByFeatureFlag(featuresProd[feature]);
}
